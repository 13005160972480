import { ColDef, GroupCellRendererParams } from 'ag-grid-community';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ActionImg } from '../../assets/actionIcon.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { rentalUnitInfo } from '../../common_lib_front/types/rentalUnitInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import dataGridStyle from './dataGrid.module.css';

const formatStatus = (passStatus: string) => {
  if (passStatus === 'inactive') {
    return 'Ready to Scan';
  }
  if (passStatus === 'incomplete') {
    return 'Incomplete';
  }
  if (passStatus === 'incomplete-rental-car') {
    return 'Incomplete Rental Car';
  }
  return passStatus;
};

const statusStyles = (passStatus: string) => {
  if (passStatus === 'active') {
    return dataGridStyle.active;
  }
  if (passStatus === 'inactive') {
    return dataGridStyle.readyToScan;
  }
  if (passStatus === 'incomplete') {
    return dataGridStyle.incomplete;
  }
  if (passStatus === 'expired') {
    return dataGridStyle.expired;
  }
  if (passStatus === 'refunded') {
    return dataGridStyle.refunded;
  }
  if (passStatus === 'incomplete-rental-car') {
    return dataGridStyle.incompleteRentalCar;
  }
  return '';
};

const PassStatus = ({ value }: { value: string }) => (
  <div className={`${dataGridStyle.gridStatusBox} ${statusStyles(value)}`}>
    {formatStatus(value)}
  </div>
);

const EditCellRender = (action: GroupCellRendererParams<rentalUnitInfo>) => {
  return (
    <Link
      to={{
        pathname: '/resident/my-rentals/edit-rental',
        state: action.data,
      }}
    >
      <div className={dataGridStyle.actionBox}>
        <button className={dataGridStyle.actionBtn}>
          <p>Edit</p>
          <ActionImg />
        </button>
      </div>
    </Link>
  );
};

const dateFormatter = ({ value }: { value: string }) => formatDate(new Date(value));

export const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  wrapHeaderText: true,
  minWidth: 100,
};

export default function useColumnDefs(): ColDef<rentalUnitInfo>[] {
  const { communityId } = useContext(CommunityContext);
  return useMemo(
    () => [
      {
        headerName: 'Actions',
        cellRenderer: EditCellRender,
        pinned: true,
        width: 100,
      },
      {
        headerName: 'Company Name',
        field: 'companyName',
      },
      {
        headerName: 'Property Address',
        field: 'address',
      },
      { field: 'propertyManagerName' },
      { field: 'propertyManagerPhone' },
      {
        headerName: 'Emergency Contact Name',
        field: 'primaryEmergencyContactName',
      },
      {
        headerName: 'Emergency Contact Phone Number',
        field: 'primaryEmergencyPhone',
      },
      {
        field: 'startDate',
        cellRenderer: dateFormatter,
      },
      {
        headerName: 'Expiration Date',
        field: 'endDate',
        cellRenderer: dateFormatter,
      },
      {
        headerName:
          communityId === 'watercolor'
            ? 'Allowed Authorized # of Guests'
            : 'Authorized Credentials',
        field: 'occupancy',
      },
      {
        headerName: 'Registration Status',
        field: 'passStatus',
        cellRenderer: PassStatus,
      },
    ],
    [communityId],
  );
}
