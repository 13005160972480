import { gql, useQuery } from '@apollo/client';

interface IProps {
  passInfoId: string;
}

interface IPassInfo {
  name: string;
  passInfoId: string;
  priceInfo?: {
    addons?: {
      description: string;
      isFree: boolean;
      name: string;
      price: number;
    }[];
  };
}

const GET_PASS_INFO = gql`
  query getPassInfo($passInfoId: String!) {
    getPassInfo(passInfoId: $passInfoId) {
      success
      error
      data {
        name
        passInfoId
        priceInfo {
          addons {
            description
            isFree
            name
            price
          }
        }
      }
    }
  }
`;

export default function usePassInfo({ passInfoId }: IProps): IPassInfo {
  const { data } = useQuery(GET_PASS_INFO, {
    variables: { passInfoId },
    fetchPolicy: 'cache-and-network',
  });

  return {
    ...data?.getPassInfo?.data,
  };
}
