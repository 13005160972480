import React, { ReactElement, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import alertIcon from '../../../assets/alert.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import HostRegistrationNav from '../../../components/hostRegistrationNav/hostRegistrationNav';
import style from './hostinformation.module.css';
import useHostInformation from './useHostInformation';

export default function HostInformation(): ReactElement {
  const history = useHistory();

  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const { registrationId, hostData, submitHandler, editHelper, error } =
    useHostInformation();

  const { communityId, featuresConfig } = useContext(CommunityContext);
  let nextPageURL = `/resident/host-registration/${registrationId}/education`;
  if (featuresConfig?.host?.initialRegistration?.rentalRegistrationStepEnabled) {
    nextPageURL = `/resident/host-registration/${registrationId}/rental-units`;
  } else {
    if (!featuresConfig?.host?.initialRegistration?.educationStepEnabled) {
      nextPageURL = `/resident/host-registration/${registrationId}/documents`;
      if (!featuresConfig?.host?.initialRegistration?.documentStepEnabled) {
        nextPageURL = `/resident/host-registration/${registrationId}/secure-checkout`;
      }
    }
  }

  const submitDataHandler = async () => {
    if (hostData.phoneNumber.length < 5) {
      setAlert('Please fill out the required info');
      setAlertColor('red');
    } else {
      await submitHandler()
        .then(() => {
          history.replace(nextPageURL);
        })
        .catch(err => {
          console.error(err);
          if (typeof err === 'string') {
            setAlert(err);
          }
        });
    }
  };

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <HostRegistrationNav
          title={
            communityId === 'sipoa'
              ? 'Step 1: Rental Property Registration'
              : communityId === 'palmettodunes'
                ? 'Step 1: Register as an Owner'
                : 'Step 1: Register as a Rental Manager'
          }
          subtitle={
            communityId === 'sipoa'
              ? "Please provide the required information below. If you are the property owner, please insert your own information. If you are a property manager, please insert information for your company's main point of contact. Additional property manager information will be required in Step 2."
              : 'Please provide the required information below'
          }
          stepNum={1}
        />
        <div className={style.alert}>
          <div className={style.alertBox}>
            <GenericAlert color={alertColor} title={alert} hidden={!alert} />
            <GenericAlert color="red" title={error} hidden={!error} />
          </div>
        </div>
        <form
          className={style.form}
          onSubmit={async (e: React.FormEvent) => {
            e.preventDefault();
            await submitDataHandler();
          }}
        >
          <div className={style.inputBox}>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="host-firstname-inpt"
                labelTitle="First Name"
                inputValue={hostData.firstName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ firstName: e.target.value });
                }}
                required
              />
            </span>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="host-lastname-inpt"
                labelTitle="Last Name"
                inputValue={hostData.lastName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ lastName: e.target.value });
                }}
                required
              />
            </span>
          </div>
          {communityId !== 'palmettodunes' && (
            <div className={style.inputBox}>
              <span className={style.inputLong}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="host-company-name"
                  labelTitle="Company Name (Property Management Companies Only)"
                  inputValue={hostData.companyName || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editHelper({ companyName: e.target.value });
                  }}
                />
              </span>
            </div>
          )}
          <div className={style.inputBox}>
            <span className={style.inputLong}>
              <PhoneInputWrapper
                standardInput
                inputTitle="Phone Number"
                value={hostData.phoneNumber}
                changeHandler={val => {
                  editHelper({ phoneNumber: val });
                }}
                required
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputLong}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="host-address-inpt"
                labelTitle="Mailing Address"
                inputValue={hostData.address}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ address: e.target.value });
                }}
                required
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="host-city-inpt"
                labelTitle="City"
                inputValue={hostData.city}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ city: e.target.value });
                }}
                required
              />
            </span>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="host-state-inpt"
                labelTitle="State"
                inputValue={hostData.state}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ state: e.target.value });
                }}
                required
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="host-zip-inpt"
                formatCode="zip-strict"
                labelTitle="Zip Code"
                inputValue={hostData.zipCode}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ zipCode: e.target.value });
                }}
                required
                minLength={5}
              />
            </span>
            {communityId === 'palmettodunes' ? (
              <span className={style.inputShort}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="host-pin-inpt"
                  labelTitle="Owner Pin"
                  inputValue={hostData.ownerPin}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editHelper({ ownerPin: e.target.value });
                  }}
                  required
                  minLength={2}
                />
                <div className={style.ownerPinText}>
                  Owner Pin (This is the number you use to request guest passes)
                </div>
              </span>
            ) : null}
            {/* {communityId === 'sipoa' ? (
              <span className={style.inputShort}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="host-pin-inpt"
                  labelTitle="Security Verification Code"
                  inputValue={hostData.ownerPin}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editHelper({ ownerPin: e.target.value });
                  }}
                  required
                  minLength={2}
                />
                <div className={style.ownerPinText}>
                  This is the code you use at the gate.
                </div>
              </span>
            ) : null} */}
          </div>
          <div className={style.alertBox}>
            <img src={alertIcon} alt="" />
            <p className={`${style.alert} textColorMedium `}>
              Please verify that the above information is current and accurate.
            </p>
          </div>
          <div className={style.btn}>
            <GenericButton size="large" color="blue" title="Continue" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}
