import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ReactElement, useState, useRef, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import UserInfo, { newUserInfo } from '../../../common_lib_front/types/userInfo';
import { formatDateWithMonthName } from '../../../common_lib_front/utilities/formatDate';
import EditProfileTemplate from '../../../components/editProfileTemplate/editProfileTemplate';
import style from './familyAndFriends.module.css';
import PopupCellRenderer from './popUpCellRender';

const GET_ALL_MEMBERS = gql`
  query GetFamilyMembers {
    getFamilyMembers {
      error
      success
      data {
        firstName
        familyMemberId
        lastName
        phoneNumber
        email
        driversLicenseNum
        driversLicenseExp
        isLicenseSelected
        userId
        memberPictureUrl
        driversLicenseState
      }
    }
  }
`;

export default function FamilyAndFriends(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const gridRef = useRef<AgGridReact>(null);

  const allNodes: any[] = [];
  gridRef.current?.api?.forEachNode((row: any) => {
    if (row.displayed) allNodes.push(row);
  });

  const { loading, data } = useQuery(GET_ALL_MEMBERS, {
    fetchPolicy: 'cache-and-network',
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
  });
  // console.log(data);

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    wrapHeaderText: true,
  };

  const [columnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
      maxWidth: 100,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      filter: 'agMultiColumnFilter',
      sortable: true,
      minWidth: 130,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      filter: 'agMultiColumnFilter',
      sortable: true,
      minWidth: 130,
    },
    {
      headerName: 'Phone Number',
      field: 'phoneNumber',
      sortable: true,
      minWidth: 150,
    },

    {
      headerName: 'Email',
      field: 'email',
      filter: 'agMultiColumnFilter',
      sortable: true,
      minWidth: 180,
    },
    {
      headerName: "Driver's License #",
      field: 'driversLicenseNum',
      filter: 'agMultiColumnFilter',
      sortable: true,
      minWidth: 180,
    },
    {
      headerName: "Driver's License State",
      field: 'driversLicenseState',
      filter: 'agMultiColumnFilter',
      sortable: true,
      minWidth: 160,
    },
    {
      headerName: 'DL Expiration',
      field: 'driversLicenseExp',
      filter: 'agMultiColumnFilter',
      sortable: true,
      minWidth: 130,
    },
    {
      headerName: 'Personal Credentials',
      field: 'alternateCredential',
      filter: 'agMultiColumnFilter',
      sortable: true,
      minWidth: 130,
    },
  ]);
  const rowData = data?.getFamilyMembers?.data.flatMap((item: any) => ({
    firstName: item.firstName,
    lastName: item.lastName,
    phoneNumber: item.phoneNumber,
    email: item.email,
    driversLicenseNum: item.driversLicenseNum,
    driversLicenseExp: item.driversLicenseExp,
    isLicenseSelected: item.isLicenseSelected,
    familyMemberId: item.familyMemberId,
    userId: item.userId,
    memberPictureUrl: item.memberPictureUrl,
    alternateCredential: item.alternateCredential,
    driversLicenseState: item.driversLicenseState,
  }));
  const onBtnExport = useCallback(() => {
    const columnKeys = [
      'lastName',
      'firstName',
      'phoneNumber',
      'email',
      'driversLicenseState',
      'alternateCredential',
      'driversLicenseExp',
    ];
    const headers: string[] = [];
    columnDefs.slice(1).forEach(col => {
      headers.push(col.headerName);
    });
    const dataToExport: string[] = [headers.join(',')];
    gridRef.current?.api.forEachNode(row => {
      if (row.displayed) allNodes.push(row);
    });
    allNodes
      .sort((a, b) => a.rowIndex - b.rowIndex)
      .forEach(row => {
        const { data: item } = row;
        const itemData: any[] = [];
        columnKeys.forEach((key: string) => {
          if (key === 'driversLicenseExp') {
            itemData.push(formatDateWithMonthName(new Date(item[key])));
          } else {
            itemData.push(`"${item[key]}"`);
          }
        });
        dataToExport.push(itemData.join(','));
      });
    const str = dataToExport.join('\r\n');

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
  }, []);
  // Quick filter event handler
  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  return (
    <EditProfileTemplate active="familyAndfriends">
      <div className={style.alert}>
        <div className={style.alertBox}>
          <GenericAlert color={alertColor} title={alert} hidden={!alert} />
        </div>
      </div>
      <div className={`${style.subHeader} mainColor`}>
        <div className={style.subHeaderLeft}>
          <div className={`${style.circle} white`} />
          <h1 className={`${style.title} textColorWhite`}>Family and Friends</h1>
        </div>

        <div className={style.subHeaderRight}>
          <Link
            to={{
              pathname: '/resident/edit-profile/add-friends-and-family',
            }}
          >
            <GenericButton
              color="yellow"
              size="large"
              className={style.addBtn}
              title="Add Family Member or Friend +"
              type="button"
            />
          </Link>
        </div>
      </div>
      <div className={style.topLine}>
        <input
          type="search"
          className={style.gridSearch}
          onChange={onFilterTextChange}
          placeholder="Search anything here .."
        />

        <button className={style.btnExport} onClick={onBtnExport}>
          Export CSV
        </button>
      </div>
      <div className={style.paginationBox}>
        {' '}
        <label htmlFor="page-num-inpt">
          Show
          <select
            className={style.selectBox}
            value={itemsPerPage}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setItemsPerPage(Number.parseInt(e.target.value, 10));
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          Per Page
        </label>
      </div>
      <div className={style.tableBox}>
        <div
          className="ag-theme-alpine"
          style={{
            height: '60vh',
            // width: '600px',
          }}
        >
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            ref={gridRef}
            paginationPageSize={itemsPerPage}
          />
        </div>
      </div>
    </EditProfileTemplate>
  );
}
