import React from 'react';
import { Link } from 'react-router-dom';
import SelectTable from '../../common_lib_front/components/selectTable/selectTable';
import { useFamilyAndFriends } from '../../common_lib_front/hooks/useFamilyAndFriends';
import {
  IAddEmployeeInfo,
  newEmployeeInfo,
} from '../../common_lib_front/types/employeeInfo';

type EmployeeSelectProps = {
  id: string;
  employee?: IAddEmployeeInfo;
  setEmployee: (val: IAddEmployeeInfo | undefined) => void;
  required?: boolean;
};

export default function EmployeeSelect(props: EmployeeSelectProps): React.ReactElement {
  const { id, employee, setEmployee, required } = props;
  const { data: employeeData } = useFamilyAndFriends();

  return (
    <SelectTable<IAddEmployeeInfo>
      id={id}
      required={required}
      displayVal={e => `${e?.firstName} ${e?.lastName}`}
      value={employee}
      validityTest={obj => !!(obj.firstName || obj.lastName || obj.driversLicenseNum)}
      noHeader
      preventClear
      setValue={setEmployee}
      data={
        employeeData?.getFamilyMembers.data?.map((e: any) => newEmployeeInfo(e)) || []
      }
      headers={[
        {
          label: 'Name',
          field: e => `${e.firstName} ${e.lastName}`,
        },
      ]}
      topHead={
        <div>
          <Link to="/resident/edit-profile/add-friends-and-family">
            Add Family and Friends
          </Link>
        </div>
      }
    />
  );
}
EmployeeSelect.defaultProps = {
  employee: undefined,
  required: undefined,
};
