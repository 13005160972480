import { ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as AddImg } from '../../assets/add.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import LoadingDiamonds from '../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import { useValidPassInfos } from '../../hooks/useValidPassInfos';
import { InviteGuestDispatchType } from '../../pages/host/inviteGuest/useInviteGuest';
import InviteGuestNav from '../inviteGuestNav/inviteGuestNav';
import {
  InviteGuestForm,
  RecipientInfo,
  formConfigType,
  formTextConfigType,
} from './inviteGuestForm';
import style from './inviteGuestForm.module.css';
import doValidate from './useRentalValidation';

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

type validator = {
  errorIf?: (data: RecipientInfo) => boolean;
  validIf?: (data: RecipientInfo) => boolean;
  message: string;
};

type InviteGuestFormContainerProps = {
  data: Array<RecipientInfo>;
  editData: InviteGuestDispatchType;
  fastPassSelected: boolean;
  setFastPassSelected?: (val: boolean) => void;
  submitHandler: () => Promise<{
    data?: (backendResponse<unknown> | undefined)[];
    errors?: (string | undefined)[];
  }>;
  error?: string;
  formConfig: formConfigType;
  successLink?: string;
  formTextConfig: formTextConfigType;
  maxPasses?: number;
  loading?: boolean;
  disabled?: boolean;
  hideNav?: boolean;
  hideUpload?: boolean;
  targetPortal?: 'guest' | 'vendor';
  skipBackendValidation?: boolean;
  validators?: validator[];
};
export default function InviteGuestFormContainer(
  props: InviteGuestFormContainerProps,
): ReactElement {
  const {
    data,
    editData,
    // fastPassSelected,
    setFastPassSelected,
    submitHandler,
    error,
    formConfig,
    formTextConfig,
    maxPasses,
    loading,
    disabled,
    hideNav,
    hideUpload,
    successLink,
    targetPortal,
    skipBackendValidation,
    validators,
  } = props;
  const history = useHistory();
  const [alert, setAlert] = useState<string>('');
  // Error message of each receipt
  const [errorMessages, setErrorMessages] = useState<(string | undefined)[]>([]);
  const submitLock = useRef<boolean>(false);
  const { featuresConfig: featuresConfig } = useContext(CommunityContext);
  // TODO this logic should be replaced by allowed pass quantity determined via pass configs
  const multiplePassesFeature = useMemo<boolean>(() => {
    const res: boolean | undefined = featuresConfig?.host?.inviteGuest?.multiplePasses;
    if (res === undefined) return true;
    return res;
  }, [featuresConfig]);

  const { allPassInfoDatas: passInfoData } = useValidPassInfos({});

  const location = useLocation();

  if (location.pathname.includes('fast-pass')) {
    setFastPassSelected?.(true);
  } else {
    setFastPassSelected?.(false);
  }

  const { communityId } = useContext(CommunityContext);

  useEffect(() => {
    if (data.length === 0) {
      editData({
        type: 'addRecipient',
        payload: {
          recipientData: {
            // willPayForPass: communityId !== 'grandcomplex' ? true : false,
            willPayForPass: Boolean(featuresConfig?.host?.inviteGuest?.hostMustPay),
          },
        },
      });
    }
  });

  useEffect(() => {
    if (typeof maxPasses === 'number' && data.length > maxPasses) {
      editData({
        type: 'deleteRecipient',
        payload: {
          recipientIdx: data.length - 1,
        },
      });
    }
  }, [data, editData, maxPasses]);

  const addRental = multiplePassesFeature
    ? (recipientIdx: number): void => {
      if (!multiplePassesFeature) return;
      editData({
        type: 'addRental',
        payload: {
          recipientIdx,
          passData: {
            passInfoId:
                data[recipientIdx]?.passes[0]?.passInfoId ||
                passInfoData[0]?.passInfoId ||
                '',
            startDate: data[recipientIdx]?.arrivalDate,
            endDate: data[recipientIdx]?.departureDate,
          },
        },
      });
    }
    : undefined;

  return (
    <div className={style.inviteBox}>
      {!hideNav && (
        <InviteGuestNav
          activeTab={location.pathname.includes('fast-pass') ? 'fast-pass' : 'invite'}
        />
      )}
      <form
        onSubmit={async e => {
          e.preventDefault();
          if (submitLock.current) return;
          submitLock.current = true;
          setAlert('');
          // test each recipient against all validators
          if (validators) {
            for (const { errorIf, validIf, message } of validators) {
              if (errorIf && data.some(i => errorIf(i))) {
                setAlert(message);
                submitLock.current = false;
                return;
              }
              if (validIf && data.some(i => !validIf(i))) {
                setAlert(message);
                submitLock.current = false;
                return;
              }
            }
          }
          // if (
          //   formConfig.phoneNumber.required &&
          //   data.some(i => i.phoneNumber.length < 11)
          // ) {
          //   setAlert(
          //     'Please enter a valid phone number. A phone number is required to issue an invite.',
          //   );
          //   submitLock.current = false;
          //   return;
          // }
          // if (formConfig.address.required && data.some(i => i.address.length < 3)) {
          //   setAlert('Please enter a valid address for all passes.');
          //   submitLock.current = false;
          //   return;
          // }
          try {
            let validationRes: Awaited<ReturnType<typeof doValidate>> | undefined =
              undefined;
            if (!skipBackendValidation) {
              validationRes = await doValidate({
                passesRentals: data.map(recipient => ({
                  rentalInfo: {
                    address: recipient.address,
                    arrivalDate: recipient.arrivalDate,
                    departureDate: recipient.departureDate,
                  },
                  passes: recipient.passes.map(pass => ({
                    passInfoId: pass.passInfoId,
                    endDate: pass.endDate,
                    startDate: pass.startDate,
                  })),
                })),
              });
            }
            const errors = validationRes?.data?.bulkValidate.data || [];
            setErrorMessages(errors);
            if (errors.some(msg => !!msg)) {
              submitLock.current = false;
              return;
            }
          } catch {
            setAlert('Something went wrong. Please try again later');
            submitLock.current = false;
            return;
          }
          submitHandler()
            .then(res => {
              if (res.data instanceof Array && res.data.every(item => item?.success)) {
                if (successLink) {
                  history.replace(successLink);
                } else if (data.some(recipient => recipient.willPayForPass)) {
                  history.replace('/invite-guest/payment');
                } else {
                  history.replace('/invite-guest/guest-list');
                }
              } else {
                setErrorMessages(res.errors?.map(item => item || '') || []);
              }
              submitLock.current = false;
            })
            .catch(err => {
              console.log(err);
              setAlert(err.message);
              submitLock.current = false;
            });
        }}
      >
        {location.pathname.includes('fast-pass') ? (
          <p className={style.fastPassInstruction}>
            *If a phone number and/or an email address is provided, the Fast Pass will be
            shared to the contact information provided.
          </p>
        ) : null}
        {location.pathname.includes('fast-pass') ? (
          <p className={style.fastPassInstruction}>
            *In order to reduce unusable passes, you will have three minutes to pay for
            fast pass invites. After this time, the passes will be deleted. Invites will
            not be sent until payment is collected.
          </p>
        ) : null}
        {communityId === 'sipoa' &&
        !location.pathname.includes('fast-pass') &&
        !location.pathname.includes('vendor-pass') ? (
            <p className={style.fastPassInstruction}>
            Rental managers can use &quot;+ Add Pass&quot; to invite multiple Guests in
            support of a single reservation.
            </p>
          ) : null}
        {communityId === 'sipoa' &&
        !location.pathname.includes('fast-pass') &&
        !location.pathname.includes('vendor-pass') ? (
            <p className={style.fastPassInstruction}>
            For an existing reservation, Rental Managers may add (invite) a Guest by
            simply inviting the Guest to the same rental address. With maximum occupancy
            levels, if a property is at the maximum level, the Rental Manager will not be
            allowed to register additional guests.
            </p>
          ) : null}
        {location.pathname.includes('vendor-pass') ? (
          <p
            className={style.fastPassInstruction}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <p>To Invite a Vendor:</p>
              <li>
                Please select a vendor from the dropdown, OR enter a business name, email,
                address, phone number.
              </li>
              <li>
                Select the destination (if applicable) and dates the vendor will require
                access.
              </li>
              <li>
                The Vendor will receive an email and/or text alerting them to the request.
              </li>
            </div>{' '}
          </p>
        ) : null}
        {['debordieu'].indexOf(communityId) > -1 && (
          <div className={style.fastPassInstruction}>
            For visitors of short-term renters, select Visitor of Guest Pass. Each rental
            unit is allowed up to 4 Visitor Passes per day. These are only valid during
            allowed hours of access for the day they are issued. Visitors of Guests are
            require to register their vehicles.
          </div>
        )}
        <GenericAlert
          title={
            alert === 'Failed to fetch' || error === 'Failed to fetch'
              ? 'There appears to be a temporary issue with your network. Please refresh this page and try again.'
              : alert || error
          }
          color="red"
          hidden={!(alert || error)}
        />
        {data.map((ri, recipientIdx) => (
          <>
            <GenericAlert
              title={errorMessages[recipientIdx]}
              color="red"
              hidden={!errorMessages[recipientIdx]}
            />
            <InviteGuestForm
              idx={recipientIdx}
              data={ri}
              disabled={disabled}
              formConfig={formConfig}
              formTextConfig={formTextConfig}
              targetPortal={targetPortal}
              editRecipient={val =>
                editData({
                  type: 'editRecipient',
                  payload: {
                    recipientIdx,
                    recipientData: val,
                  },
                })
              }
              editRental={(rentalIdx, val) =>
                editData({
                  type: 'editRental',
                  payload: {
                    recipientIdx,
                    rentalIdx,
                    passData: val,
                  },
                })
              }
              // editRental={(rentalIdx, val) =>
              //   editData({
              //     type: 'editRental',
              //     payload: {
              //       recipientIdx,
              //       rentalIdx,
              //       passData: val,
              //     },
              //   })
              // }
              deleteRecipient={() =>
                editData({
                  type: 'deleteRecipient',
                  payload: {
                    recipientIdx,
                  },
                })
              }
              // deleteRecipient={() =>
              //   editData({
              //     type: 'deleteRecipient',
              //     payload: {
              //       recipientIdx,
              //     },
              //   })
              // }
              deleteRental={rentalIdx =>
                editData({
                  type: 'deleteRental',
                  payload: {
                    recipientIdx,
                    rentalIdx,
                  },
                })
              }
              // deleteRental={rentalIdx =>
              //   editData({
              //     type: 'deleteRental',
              //     payload: {
              //       recipientIdx,
              //       rentalIdx,
              //     },
              //   })
              // }
              addRental={addRental ? () => addRental(recipientIdx) : undefined}
              //   !multiplePassesFeature
              //     ? undefined
              //     : () =>
              //       editData({
              //         type: 'addRental',
              //         payload: {
              //           recipientIdx,
              //           passData: {
              //             passInfoId:
              //               data[recipientIdx]?.passes[0]?.passInfoId ||
              //               passInfoData[0]?.passInfoId ||
              //               '',
              //             startDate: data[recipientIdx]?.arrivalDate,
              //             endDate: data[recipientIdx]?.departureDate,
              //           },
              //         },
              //       })
              // }
            />
          </>
        ))}
        <div className={style.btnBox}>
          {loading ? (
            <LoadingDiamonds />
          ) : (
            <>
              <p className={style.fastPassInstruction}>
                {formTextConfig.largeNumberWarning ||
                  '*For large numbers of guests, invites may take up to a minute to process. Please do not leave the page.'}
              </p>
              {!hideUpload && (
                <Link className={style.uploadBtn} to="/invite-guest/upload-guest-list">
                  <GenericButton
                    title="Upload Guest List"
                    type="button"
                    disabled={disabled}
                  />
                </Link>
              )}
              <div className={style.inviteBtn}>
                <GenericButton title="Invite" type="submit" disabled={disabled} />
              </div>
              {(maxPasses === undefined || data.length < maxPasses) && (
                <div className={style.addBtn}>
                  <button
                    type="button"
                    disabled={disabled}
                    onClick={() =>
                      editData({
                        type: 'addRecipient',
                        payload: {
                          recipientData: {
                            // willPayForPass: communityId !== 'grandcomplex' ? true : false,
                            willPayForPass: Boolean(
                              featuresConfig?.host?.inviteGuest?.hostMustPay,
                            ),
                          },
                        },
                      })
                    }
                  >
                    <AddImg />
                    <h4>Add Reservation</h4>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
}

InviteGuestFormContainer.defaultProps = {
  error: undefined,
  inputRequirements: 'require-all',
};
