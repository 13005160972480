import { gql, useMutation, useQuery } from '@apollo/client';
import { keyBy, map, uniq } from 'lodash';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';
import PassInfo, {
  PaymentStatuses,
  newPassInfo,
} from '../../../../common_lib_front/types/passInfo';
import {
  newRegistrationInfo,
  registrationInfo,
} from '../../../../common_lib_front/types/registrationInformation';
import { newVehicleInfo } from '../../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import { createPaymentSession } from '../../../../hooks/usePaymentSession';
import { GET_PRICE_INFO } from '../registerVehicle/useRegisterVehicle';

const STEP_THREE_QUERY = gql`
  query Step4Query($registrationId: String!) {
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        number
        externalCredentialNumber
        startDate
        endDate
        status
        passInfoId
        passType
        addons
        paid
      }
    }
    getUserInfo {
      success
      error
      data {
        userId
        phoneNumber
        address
        city
        state
        zipCode
      }
    }
  }
`;

type STEP_THREE_VARS = {
  registrationId: string;
};

type STEP_THREE_RES = {
  getPassesByRegistration: backendResponse<
    {
      passId: string;
      number: number;
      startDate: string;
      endDate: string;
      status: string;
      passInfoId: string;
      passType: string;
      addons: string[];
      paid: boolean;
    }[]
  >;
  getUserInfo: backendResponse<{
    userId: string;
    phoneNumber: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
  }>;
};

const STEP_THREE_SUMBIT = gql`
  mutation DoStripeCharge(
    $cancelUrl: String!
    $successUrl: String!
    $paymentSessionId: String!
    $paymentMethod: String!
  ) {
    doStripeCharge(
      cancelURL: $cancelUrl
      successURL: $successUrl
      paymentSessionId: $paymentSessionId
      paymentMethod: $paymentMethod
    ) {
      success
      error
      url
    }
  }
`;
type STEP_THREE_SUMBIT_VARS = {
  cancelUrl: string;
  successUrl: string;
  paymentSessionId: string;
  paymentMethod: string;
};
type STEP_THREE_SUMBIT_RES = {
  doStripeCharge: {
    success: boolean;
    error: string;
    url: string;
  };
};

const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        isRental
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
      }
    }
  }
`;

const GET_PASS_IMAGE = gql`
  query GetPassImage($passId: String!) {
    getPassImage(passId: $passId) {
      success
      error
      data {
        passId
        number
        externalCredentialNumber
        url
      }
    }
  }
`;

// Change the registration step number
const DO_COMPLETE_STEPS = gql`
  mutation doCompleteStep($registrationId: String!) {
    editRegistrationStepNumber(
      stepNumber: 3
      registrationId: $registrationId
      registrationType: "residentPass"
    ) {
      error
      success
    }
  }
`;

type useSecureCheckoutRes = {
  loading: boolean;
  userInfo: registrationInfo;
  registrationId: string;
  passes: Array<any>;
  redirect: string;
  submitHandler: (isFree: boolean) => void;
  errorMsg: string | undefined;
  selectedPayment: string;
  setSelectedPayment: (s: string) => void;
};

export default function useSecureCheckout(): useSecureCheckoutRes {
  const [passes, setPasses] = useState<PassInfo[]>([]);
  const [redirect, setRedirect] = useState<string>('');
  const { registrationId } = useParams<{ registrationId: string }>();
  const [userInfo, setUserInfo] = useState<registrationInfo>(newRegistrationInfo());
  const [selectedPayment, setSelectedPayment] = useState<string>('card');
  const { communityId } = useContext(CommunityContext);

  const { refetch } = useQuery<STEP_THREE_RES, STEP_THREE_VARS>(STEP_THREE_QUERY, {
    variables: {
      registrationId,
    },
    onCompleted: async d => {
      if (!d.getPassesByRegistration.success) {
        return;
      }

      const res = d.getPassesByRegistration.data?.map(p => newPassInfo(p as any)) || [];
      Promise.all(
        res.map((element, idx: number) =>
          backendClient
            .query({
              query: GET_VEHICLE,
              variables: { passId: element.passId },
            })
            .then(v => {
              res[idx].vehicle = newVehicleInfo(v.data.getVehicle.data);
            })
            .catch(e => {
              console.log(e);
            }),
        ),
      ).then(() => {
        // setPasses(res);
        setUserInfo(newRegistrationInfo(d.getUserInfo.data));
      });

      // Get passes' prices by passInfoId
      const passInfoIds = uniq(map(res, 'passInfoId'));
      try {
        const priceInfoRes = await Promise.all(
          map(passInfoIds, (id: string) =>
            backendClient.query({
              query: GET_PRICE_INFO,
              variables: { passInfoId: id },
            }),
          ),
        );
        // Set price info to passes by passInfoId
        const priceInfoMap = keyBy(
          map(priceInfoRes, 'data.getPriceInfo.data'),
          'passInfoId',
        );
        res?.forEach((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.priceInfo = priceInfoMap[item.passInfoId];
        });
      } catch (error: any) {
        console.log(error);
      }

      setPasses(res);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [doSubmit, { error, loading: doSubmitLoading }] = useMutation<
    STEP_THREE_SUMBIT_RES,
    STEP_THREE_SUMBIT_VARS
  >(STEP_THREE_SUMBIT, {
    onError: () => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        setRedirect(
          `${window.location.origin}/${communityId}/resident/get-passes/resident-pass/
          ${registrationId || 0}/receipt`,
        );
      } else {
        refetch();
      }
    },
    onCompleted: d => {
      if (d.doStripeCharge.success) document.location.href = d.doStripeCharge.url;
    },
  });

  // Change the registration step to confirm registration is done
  const [doComplete] = useMutation(DO_COMPLETE_STEPS, {
    variables: {
      registrationId,
    },
  });

  const submitHandler = async (isFree: boolean) => {
    Promise.all(
      passes.map((element, idx: number) =>
        backendClient
          .mutate({
            mutation: GET_PASS_IMAGE,
            variables: { passId: element.passId },
          })
          .then(v => {
            // console.log('success', v);
          })
          .catch(e => {
            console.log(e);
          }),
      ),
    ).then(async () => {
      if (
        passes.every(
          p =>
            p.paid === PaymentStatuses.paid ||
            p.paid === PaymentStatuses.noCharge ||
            selectedPayment === 'Cash/Physical Check',
        )
      ) {
        // Set the registation step to confirm registation is done
        doComplete();
        setRedirect(`/resident/get-passes/resident-pass/${registrationId}/receipt`);
        return;
      }
      doComplete();
      const paymentId = (
        await createPaymentSession({
          newPaymentSession: {
            registrationIds: [registrationId],
          },
        })
      ).data?.createPaymentSession.data?.paymentSessionId;
      if (!isFree) {
        doSubmit({
          variables: {
            paymentMethod: selectedPayment,
            paymentSessionId: paymentId || '',
            cancelUrl: window.location.href,
            successUrl: `${
              window.location.origin
            }/${communityId}/resident/get-passes/resident-pass/${
              registrationId || 0
            }/receipt`,
          },
        });
      } else {
        document.location.href = `${
          window.location.origin
        }/${communityId}/resident/get-passes/resident-pass/${
          registrationId || 0
        }/receipt`;
      }
    });
  };

  return {
    loading: doSubmitLoading,
    userInfo,
    registrationId,
    passes,
    redirect,
    submitHandler,
    errorMsg: error?.message,
    selectedPayment,
    setSelectedPayment,
  };
}
