import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import { PassBuilderDateInputWithFetchDurtionInfo } from '../../common_lib_front/components/passBuilderDateRange';
import usePassInfo from '../../common_lib_front/hooks/usePassInfo';
import { useVehicleConfig } from '../../common_lib_front/hooks/useVehicleConfig';
import { IAddEmployeeInfo } from '../../common_lib_front/types/employeeInfo';
import PassInfo from '../../common_lib_front/types/passInfo';
import EmployeeSelect from './employeeSelect';
import style from './passForm.module.css';
import VehicleSelect from './vehicleSelect';

export type passFormInput = PassInfo & {
  employee?: IAddEmployeeInfo;
  destination?: string;
};

const defaultItemList: Array<FieldItem> = [
  {
    title: 'Assigned Person/Driver',
    name: 'employee',
    value: 'required',
    displayOption: 'employee-table-select',
    vehiclInfoConfigField: 'driverSelect',
  },
  {
    title: 'Vehicle',
    name: 'vehicle',
    value: 'required',
    displayOption: 'vehicle-table-select',
    vehiclInfoConfigField: 'vehicleSelect',
  },
];

const communityItemLists: Record<string, Array<FieldItem>> = {
  palmettodunes: [
    {
      title: 'Assigned Person/Driver',
      name: 'employee',
      value: 'disabled',
      displayOption: 'employee-table-select',
    },
    {
      title: 'Vehicle',
      name: 'vehicle',
      value: 'disabled',
      displayOption: 'vehicle-table-select',
    },
  ],
  sipoa: [
    {
      title: 'Assigned Person/Driver',
      name: 'employee',
      value: 'required',
      displayOption: 'employee-table-select',
    },
    {
      title: 'Vehicle',
      name: 'vehicle',
      value: 'required',
      displayOption: 'vehicle-table-select',
    },
  ],
};

type PassFormProps = {
  data: passFormInput;
  idx: number;
  title?: string;
  removeHandler: () => void;
  updateHandler: (obj: Partial<passFormInput>) => void;
  allowRemove?: boolean;
  showViewBtn?: boolean;
  allowCompleteLater?: boolean;
  allowEditAddons?: boolean;
};

type FieldItem = {
  title: string;
  name: string;
  value: string;
  vehiclInfoConfigField?: string;
  displayOption?: 'text' | 'vehicle-table-select' | 'employee-table-select';
};

export const PassForm = (props: PassFormProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(true);
  const {
    idx,
    data,
    allowRemove,
    showViewBtn,
    updateHandler,
    title,
    allowCompleteLater,
    allowEditAddons,
  } = props;
  const willCompleteLater = useMemo(
    () => data.status === 'incomplete-rental-car',
    [data.status],
  );
  const { t } = useTranslation();
  const { communityId } = useContext(CommunityContext);
  const [itemList, setItemList] = useState<Array<FieldItem>>(
    communityItemLists[communityId] || defaultItemList,
  );

  const passInfo = usePassInfo({ passInfoId: data.passInfoId });
  useVehicleConfig({
    variables: {
      passInfoId: passInfo.passInfoId,
    },
    skip: !passInfo.passInfoId,
    onCompleted: vehicleConfig => {
      const cfg = vehicleConfig?.getVehicleInfoConfig?.data;
      if (!vehicleConfig.getVehicleInfoConfig.success || !cfg) {
        return;
      }
      console.log(vehicleConfig.getVehicleInfoConfig.data);
      const res = [...(communityItemLists[communityId] || defaultItemList)];
      for (let i = 0; i < res.length; i += 1) {
        if (!res[i]) continue;
        if (!res[i].vehiclInfoConfigField) continue;
        const rItem = { ...res[i] };
        rItem.value =
          ((cfg as any)[rItem.vehiclInfoConfigField || ''] as string | undefined) ||
          rItem.value;
        res[i] = rItem;
      }
      setItemList(res);
    },
  });

  const isAddon = (item: any): boolean => data.addons?.indexOf(item.name) !== -1;

  const toggleAddon = (item: any) => {
    const updatedList = [...data.addons];
    const position = updatedList.indexOf(item.name);

    if (position === -1) {
      updatedList.push(item.name);
    } else {
      updatedList.splice(position, 1);
    }

    props.updateHandler({
      addons: updatedList,
    });
  };

  return (
    <div className={style.infoFormBox}>
      <div className={`${style.header} superDarkGrey `}>
        <div className={style.leftPart}>
          <div className={style.btnDown}>
            <GenericButton
              color="transparent"
              shape="drop-down"
              title={open ? '<' : '>'}
              clickHandler={() => setOpen(!open)}
            />
          </div>
          <strong className={`${style.header} textColorWhite `}>
            {title || `${idx + 1}. ${passInfo?.name}`}
          </strong>
        </div>
        {showViewBtn ? (
          <div className={style.view}>
            <Link to={{ pathname: '/resident/pass-details', state: { data } }}>
              <GenericButton shape="view" title={t('View')} />
            </Link>
          </div>
        ) : null}
        {allowRemove ? (
          <div className={style.btnRemove}>
            <GenericButton
              color="transparent"
              title={t('Remove')}
              clickHandler={() => props.removeHandler()}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {open ? (
        <div>
          {/* {allowCompleteLater && (
            <div className={style.inputLong}>
              <InputField
                inputType="checkbox"
                htmlFor={`incomplete-inpt-${idx}`}
                labelTitle="Complete Information Later"
                checkBox
                checked={data.status === 'incomplete-rental-car'}
                changeHandler={e =>
                  updateHandler({
                    status: e.target.checked ? 'incomplete-rental-car' : 'incomplete',
                  })
                }
              />
              {willCompleteLater && (
                <div className={style.incompleteWarning}>
                  Notice: By checking this box, you will be required to complete requested
                  information later before this pass can be used.
                </div>
              )}
            </div>
          )} */}
          <div className={style.inputShortBox}>
            {itemList
              .filter(item => item.value !== 'disabled')
              .map((item, key) => {
                switch (item.displayOption) {
                  case 'employee-table-select':
                    return (
                      <div className={style.inputShort} key={key + 'employee'}>
                        {/* control is nested in vehicle select eslint cannot find it */}
                        <label htmlFor={`${idx}-employee-select`}>
                          {`${t(item.title)}${item.value === 'required' ? '*' : ''}`}
                          <EmployeeSelect
                            id={`${idx}-employee-select`}
                            required={
                              willCompleteLater && allowCompleteLater
                                ? false
                                : item.value === 'required'
                            }
                            employee={
                              data.employee || {
                                firstName: data.vehicle.primaryDriverName,
                                lastName: '',
                                driversLicenseNum:
                                  data.vehicle.licensePrimaryDriver || '',
                              }
                            }
                            setEmployee={employee => updateHandler({ employee })}
                          />
                        </label>
                      </div>
                    );
                  case 'vehicle-table-select':
                    return (
                      <div className={style.inputShort} key={key + 'vehicle'}>
                        {/* control is nested in vehicle select eslint cannot find it */}
                        <label htmlFor={`${idx}-vehicle-select`}>
                          {`${t(item.title)}${item.value === 'required' ? '*' : ''}`}
                          <VehicleSelect
                            id={`${idx}-vehicle-select`}
                            required={
                              willCompleteLater && allowCompleteLater
                                ? false
                                : item.value === 'required'
                            }
                            vehicle={data.vehicle}
                            setVehicle={vehicle =>
                              updateHandler({
                                vehicle,
                              })
                            }
                          />
                        </label>
                      </div>
                    );
                  case 'text':
                  default:
                    return (
                      <div className={style.inputShort} key={key + 'input'}>
                        <InputField
                          closedInput
                          htmlFor={`${item.name}-inpt-${idx}`}
                          required={
                            willCompleteLater && allowCompleteLater
                              ? false
                              : item.value === 'required'
                          }
                          labelTitle={
                            t(item.title) + (item.value === 'required' ? '*' : '')
                          }
                          inputType="text"
                          inputValue={data.vehicle[item.name]}
                          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.updateHandler({
                              [item.name]: e.target.value,
                            });
                          }}
                        />
                      </div>
                    );
                }
              })}
          </div>
          <div className={style.tableContainer}>
            <PassBuilderDateInputWithFetchDurtionInfo
              startDate={data.startDate}
              endDate={data.endDate}
              setter={updateHandler}
              passInfoId={data.passInfoId}
              autoSetDefault={cfg => [0, 1].includes(cfg.type)}
              id={`date-range-${idx}-inpt`}
              required
            />
          </div>
          {passInfo.priceInfo?.addons
            ?.filter(item => allowEditAddons || isAddon(item))
            ?.map((item, adx: number) => (
              // eslint-disable-next-line react/jsx-key
              <div className={style.addonContainer}>
                <input
                  type="checkbox"
                  className={style.checkBox}
                  id={`addon-${adx}-inpt-${idx}`}
                  checked={isAddon(item)}
                  onChange={() => toggleAddon(item)}
                  disabled={!allowEditAddons}
                />
                <label className={style.addonLabel} htmlFor={`addon-${adx}-inpt-${idx}`}>
                  {!item.isFree && allowEditAddons ? `$${item.price} | ` : ''}
                  {item.name}
                </label>
              </div>
            ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
PassForm.defaultProps = {
  allowRemove: true,
  showViewBtn: false,
  allowCompleteLater: true,
  allowEditAddons: true,
  title: undefined,
};

export default PassForm;
