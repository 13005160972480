import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import ScormWrapper from '../../../common_lib_front/components/scormWrapper/scormWrapper';
import HostRegistrationNav from '../../../components/hostRegistrationNav/hostRegistrationNav';
import style from './education.module.css';

const EDIT_STEP_NUMBER = gql`
  mutation EditRegistrationStepNumber($registrationId: String!) {
    editRegistrationStepNumber(stepNumber: 2, registrationId: $registrationId) {
      success
      error
    }
  }
`;

export default function Education(): ReactElement {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [doNextStep] = useMutation(EDIT_STEP_NUMBER);
  const history = useHistory();
  const [showBackup, setShowBackup] = useState<boolean>(false);
  const currentCommunityData = useContext(CommunityContext);

  const submitHandler = useCallback(() => {
    doNextStep({ variables: { registrationId } }).then(() => {
      let nextPageURL = `/resident/host-registration/${registrationId}/documents`;
      if (
        !currentCommunityData?.featuresConfig?.host?.initialRegistration
          ?.documentStepEnabled
      ) {
        nextPageURL = `/resident/host-registration/${registrationId}/secure-checkout`;
      }
      history.replace(nextPageURL);
    });
  }, [doNextStep, history, registrationId, currentCommunityData]);

  if (showBackup) {
    console.warn('Scorm load error');
  }
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <HostRegistrationNav stepNum={2} title="" subtitle="" />
        {/* <GenericAlert
          color="red"
          title="Your community orientation failed to load. Please refresh this page, check
           your connection, and try again later if you continue to have problems"
          hidden={!showBackup}
        /> */}
        <div className={style.videoBox}>
          <div className={style.videoContainer}>
            <ScormWrapper
              continueHandler={submitHandler}
              portal="resident"
              loadFailureHandler={() => setShowBackup(true)}
            />
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton
            title="Continue"
            size="large"
            color="blue"
            clickHandler={submitHandler}
          />
        </div>
      </div>
    </div>
  );
}
