import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { ADDRESS_VALIDATION } from '../../../common_lib_front/hooks/useAddressValidation';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import {
  registrationInfo,
  newRegistrationInfo,
} from '../../../common_lib_front/types/registrationInformation';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { getUserDetails } from '../../../common_lib_front/utilities/store';
const RESIDENT_INFO_QUERY = gql`
  query ResidentRegistrationStep1Query {
    getResidentProfile {
      success
      error
      data {
        userId
        firstName
        lastName
        email
        phoneNumber
        altPhoneNum
        mailingStreet
        mailingUnit
        mailingCity
        mailingState
        mailingZipCode
        emergencyFirstName
        emergencyLastName
        emergencyPhoneNumber
        emergencyEmail
        ownerPin
        mailAddSameasCommunityAdd
      }
    }
    getPropertiesByUser {
      success
      error
      data {
        zipCode
        state
        city
        street
        unit
        propertyId
      }
    }
  }
`;

type RESIDENT_INFO_VARS = {
  registrationId: string;
};

type RESIDENT_INFO_RES = {
  getResidentProfile: backendResponse<{
    userId: string | null;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    altPhoneNum: string;
    mailingStreet: string;
    mailingUnit: string;
    mailingCity: string;
    mailingState: string;
    mailingZipCode: string;
    emergencyFirstName: string;
    emergencyLastName: string;
    emergencyPhoneNumber: string;
    emergencyEmail: string;
    ownerPin: string;
    mailAddSameasCommunityAdd: boolean | null;
  }>;
  getPropertiesByUser: backendResponse<
    [
      {
        propertyId: string;
        userId: string;
        registrationId: string;
        street: string;
        unit?: string;
        city: string;
        state: string;
        zipCode: string;
      },
    ]
  >;
};

const CREATE_RESIDENT_PROFILE = gql`
  mutation Mutation($profileInfo: ResidentProfileInput!) {
    insertResidentProfile(profileInfo: $profileInfo) {
      success
      error
      data {
        userId
      }
    }
  }
`;

const CREATE_RESIDENT_POPERTY = gql`
  mutation Mutation($propertyInfo: ResidentPropertyInput!) {
    insertResidentProperty(propertyInfo: $propertyInfo) {
      success
      error
      data {
        propertyId
      }
    }
  }
`;

const EDIT_RESIDENT_INFO = gql`
  mutation EditResidentProfile($newProfileInfo: UpdateResidentProfileInput!) {
    editResidentProfile(newProfileInfo: $newProfileInfo) {
      success
      error
    }
  }
`;

type INSERT_RESIDENT_PROFILE_RES = {
  insertResidentProfile: backendResponse<{
    userId: string;
    mailingZipCode: string;
    mailingStreet: string;
    mailingUnit: string;
    mailingState: string;
    mailingCity: string;
    ownerPin: string;
    phoneNumber: string;
    altPhoneNum: string;
    lastName: string;
    firstName: string;
    email: string;
    emergencyFirstName: string;
    emergencyEmail: string;
    emergencyLastName: string;
    emergencyPhoneNumber: string;
    mailAddSameasCommunityAdd: boolean | null;
  }>;
};

type INSERT_RESIDENT_PROPERTY_RES = {
  insertResidentProperty: backendResponse<{
    street: string;
    unit: string | '';
    city: string;
    state: string;
    zipCode: string;
    propertyId: string;
  }>;
};

type INSERT_RESIDENT_PROPERTY_VARS = {
  propertyInfo: {
    street: string;
    unit: string | '';
    city: string;
    state: string;
    zipCode: string;
    // propertyId: string;
  };
};

type INSERT_RESIDENT_PROFILE_VARS = {
  profileInfo: {
    mailingZipCode: string;
    mailingStreet: string;
    mailingUnit: string;
    mailingState: string;
    mailingCity: string;
    ownerPin: string;
    phoneNumber: string;
    altPhoneNum: string;
    lastName: string;
    firstName: string;
    email: string;
    emergencyFirstName: string;
    emergencyEmail: string;
    emergencyLastName: string;
    emergencyPhoneNumber: string;
    mailAddSameasCommunityAdd: boolean | null;
  };
};

type EDIT_RESIDENT_PROFILE_RES = {
  editResidentProfile: backendResponse<{
    mailingZipCode: string;
    mailingStreet: string;
    mailingUnit: string;
    mailingState: string;
    mailingCity: string;
    ownerPin: string;
    phoneNumber: string;
    altPhoneNum: string;
    lastName: string;
    firstName: string;
    email: string;
    emergencyFirstName: string;
    emergencyEmail: string;
    emergencyLastName: string;
    emergencyPhoneNumber: string;
    mailAddSameasCommunityAdd: boolean | null;
  }>;
};

type EDIT_RESIDENT_PROFILE_VARS = {
  newProfileInfo: {
    mailingZipCode: string;
    mailingStreet: string;
    mailingUnit: string;
    mailingState: string;
    mailingCity: string;
    ownerPin: string;
    phoneNumber: string;
    altPhoneNum: string;
    lastName: string;
    firstName: string;
    email: string;
    emergencyFirstName: string;
    emergencyEmail: string;
    emergencyLastName: string;
    emergencyPhoneNumber: string;
    mailAddSameasCommunityAdd: boolean | null;
  };
};

const EDIT_STEP_NUMBER = gql`
  mutation EditRegistrationStepNumber($registrationId: String!) {
    editRegistrationStepNumber(
      stepNumber: 2
      registrationId: $registrationId
      registrationType: "resident"
    ) {
      success
      error
    }
  }
`;

type EDIT_STEP_NUMBER_VARS = {
  registrationId: string;
};

type EDIT_STEP_NUMBER_RES = {
  editRegistrationStepNumber: backendResponse<undefined>;
};

type registrationInitData = {
  registrationId: string;
  registrationData: registrationInfo;
  registrationLoading: boolean;
  registrationError?: string;
  submitHandler: () => Promise<any>;
  editHelper: (obj: any) => void;
  fetchAddressValidation?: (val: string) => Promise<Array<string>> | undefined;
  searchSelectorHandler?: (val: string) => void;
  addProperty?: (propertyInfo: any) => void;
  deleteProperty: (id: string) => void;
  // searchHandlerForMailingHandler?: (val: string) => void;
};

const DELETE_ADDRESS_PROPERTY = gql`
  mutation deleteResidentProperty($propertyId: String!) {
    deleteResidentProperty(propertyId: $propertyId) {
      success
      error
    }
  }
`;

export default function useRegistrationInformation(): registrationInitData {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [addressList, setAddressList] = useState<Array<any>>([]);
  const [editData, setEditData] = useState<
    registrationInfo & {
      _residentProfileExists?: boolean;
    }
  >(newRegistrationInfo());
  const { communityId } = useContext(CommunityContext);
  const userDetails = getUserDetails();

  const [doDeleteProperty] = useMutation(DELETE_ADDRESS_PROPERTY, {
    onError: e => {
      // console.log(e.message);
    },
    onCompleted: d => {
      if (d.doDeleteProperty.success) {
        // console.log(editData.communityAddr);
      }
    },
  });

  const deleteProperty = (id: string) => {
    doDeleteProperty({
      variables: {
        propertyId: id,
      },
    });
  };

  const editHelper = (obj: any) => {
    setEditData({ ...editData, ...obj });
  };

  useEffect(() => {
    editHelper(userDetails);
  }, [userDetails]);

  const {
    data: registrationData,
    loading: registrationLoading,
    error: registrationError,
  } = useQuery<RESIDENT_INFO_RES, RESIDENT_INFO_VARS>(RESIDENT_INFO_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { registrationId },
    onCompleted: d => {
      if (d.getResidentProfile.success && d.getPropertiesByUser.success) {
        const profileData = d.getResidentProfile.data;
        const lengthAddre: number = d?.getPropertiesByUser?.data?.length || 0;
        const propertiesData = d.getPropertiesByUser.data?.[lengthAddre - 1];
        editHelper({
          userId: profileData?.userId,
          altPhoneNum: profileData?.altPhoneNum,
          phoneNumber: profileData?.phoneNumber,
          lastName: profileData?.lastName,
          firstName: profileData?.firstName,
          email: profileData?.email,
          mailingZipCode: profileData?.mailingZipCode,
          mailingState: profileData?.mailingState,
          mailingCity: profileData?.mailingCity,
          mailingStreet: profileData?.mailingStreet,
          mailingUnit: profileData?.mailingUnit,
          ownerPin: profileData?.ownerPin,
          emergencyFirstName: profileData?.emergencyFirstName,
          emergencyLastName: profileData?.emergencyLastName,
          emergencyPhoneNumber: profileData?.emergencyPhoneNumber,
          emergencyEmail: profileData?.emergencyEmail,
          zipCode: propertiesData?.zipCode,
          state: propertiesData?.state,
          city: propertiesData?.city,
          street: propertiesData?.street,
          unit: propertiesData?.unit,
          mailAddSameasCommunityAdd: profileData?.mailAddSameasCommunityAdd,
          communityAddr: d.getPropertiesByUser.data?.length
            ? d.getPropertiesByUser.data
            : [],
          _residentProfileExists: true,
        });
      }
    },
  });

  const [doEditResidentInfo] = useMutation<
    EDIT_RESIDENT_PROFILE_RES,
    EDIT_RESIDENT_PROFILE_VARS
  >(EDIT_RESIDENT_INFO);

  const [doInsertResidentProfile] = useMutation<
    INSERT_RESIDENT_PROFILE_RES,
    INSERT_RESIDENT_PROFILE_VARS
  >(CREATE_RESIDENT_PROFILE);

  const [doCreateResidentProperty] = useMutation<
    INSERT_RESIDENT_PROPERTY_RES,
    INSERT_RESIDENT_PROPERTY_VARS
  >(CREATE_RESIDENT_POPERTY);

  const [doAdvanceStepNum] = useMutation<EDIT_STEP_NUMBER_RES, EDIT_STEP_NUMBER_VARS>(
    EDIT_STEP_NUMBER,
  );

  /**
   * Edit resident profile object if it exists,
   * create it if it does not exist.
   * Create resident properties.
   * Advance registration step number.
   */
  const submitHandler = async () => {
    if (editData?._residentProfileExists) {
      await doEditResidentInfo({
        variables: {
          newProfileInfo: {
            // TODO use object.assign and a default or something
            mailingZipCode: editData?.mailingZipCode,
            mailingStreet: editData?.mailingStreet,
            mailingUnit: editData?.mailingUnit,
            mailingState: editData?.mailingState,
            mailingCity: editData?.mailingCity,
            ownerPin: editData?.ownerPin,
            phoneNumber: editData?.phoneNumber,
            altPhoneNum: editData?.altPhoneNum,
            lastName: editData?.lastName,
            firstName: editData?.firstName,
            email: editData?.email,
            emergencyFirstName: editData?.emergencyFirstName,
            emergencyEmail: editData?.emergencyEmail,
            emergencyLastName: editData?.emergencyLastName,
            emergencyPhoneNumber: editData?.emergencyPhoneNumber,
            mailAddSameasCommunityAdd: editData?.mailAddSameasCommunityAdd,
          },
        },
      });
      await doCreateResidentProperty({
        variables: {
          propertyInfo: {
            street: editData?.street,
            unit: editData?.unit || '',
            city: editData?.city,
            state: editData?.state,
            zipCode: editData?.zipCode,
            // propertyId: editData?.propertyId,
          },
        },
      });
    } else {
      await doInsertResidentProfile({
        variables: {
          profileInfo: {
            mailingZipCode: editData?.mailingZipCode,
            mailingStreet: editData?.mailingStreet,
            mailingUnit: editData?.mailingUnit,
            mailingState: editData?.mailingState,
            mailingCity: editData?.mailingCity,
            ownerPin: editData?.ownerPin,
            phoneNumber: editData?.phoneNumber,
            altPhoneNum: editData?.altPhoneNum,
            lastName: editData?.lastName,
            firstName: editData?.firstName,
            email: editData?.email,
            emergencyFirstName: editData?.emergencyFirstName,
            emergencyEmail: editData?.emergencyEmail,
            emergencyLastName: editData?.emergencyLastName,
            emergencyPhoneNumber: editData?.emergencyPhoneNumber,
            mailAddSameasCommunityAdd: editData?.mailAddSameasCommunityAdd || null,
          },
        },
      });
    }
    await doAdvanceStepNum({
      variables: {
        registrationId,
      },
    });
  };

  const fetchAddressValidation = useCallback((val: string) => {
    if (val && val.length > 1) {
      return backendClient
        .query({
          query: ADDRESS_VALIDATION,
          variables: { address: val },
        })
        .then((d: any) => {
          setAddressList(d.data.addressValidation.data);
          return d.data.addressValidation.data.map((a: { address: string }) => a.address);
        });
    }
    return undefined;
  }, []);

  const searchSelectorHandler = useCallback(
    (val: string) => {
      const data = addressList.find(a => a.address === val);
      if (data) {
        const res = editData;
        res.street = data.address;
        res.unit = '';
        res.state = data.state;
        res.city = data.city;
        res.zipCode = data.zip;
        if (editData.mailAddSameasCommunityAdd) {
          res.mailingCity = res.city;
          res.mailingState = res.state;
          res.mailingStreet = res.street;
          res.mailingUnit = res.unit;
          res.mailingZipCode = res.zipCode;
        }
        editHelper(res);
      }
    },
    [addressList],
  );

  const addProperty = (propertyInfo: any) => {
    doCreateResidentProperty({
      onError: e => {
        // console.log('error', e);
      },
      variables: {
        propertyInfo: propertyInfo,
      },
    }).then((d: any) => {
      if (d?.data?.insertResidentProperty?.success) {
        editHelper(
          [editData].map((data: any) => {
            propertyInfo.propertyId = d.data.insertResidentProperty.data[0].propertyId;
            data.communityAddr = data.communityAddr.concat([propertyInfo]);
            return data;
          })[0],
        );
      }
    });
  };

  // const searchHandlerForMailingHandler = useCallback(
  //   (val: string) => {
  //     const data = addressList.find(a => a.address === val);
  //     const res = editData;
  //     if (data) {
  //       res.mailingStreet = data.address;
  //       res.mailingCity = data.city;
  //       res.mailingState = data.state;
  //       res.mailingUnit = data.address;
  //       res.mailingZipCode = data.zip;
  //       editHelper(res);
  //     }
  //   },
  //   [addressList],
  // );

  return {
    registrationId,
    registrationData: editData,
    registrationLoading,
    registrationError: registrationError?.message || undefined,
    submitHandler,
    editHelper,
    fetchAddressValidation,
    searchSelectorHandler,
    addProperty,
    deleteProperty,
    // searchHandlerForMailingHandler,
  };
}
