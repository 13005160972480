import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import { backendTokenResponse } from '../../common_lib_front/types/backendResponse';
import store from '../../common_lib_front/utilities/store';
import { Footer } from '../../components/footer/footer';
import style from './createAccount.module.css';
import useCreateAccountTexts from './createAccountTexts';

const REGISTER_MUTATION = gql`
  mutation RegisterUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $registrationCode: String!
  ) {
    registerUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      registrationCode: $registrationCode
    ) {
      success
      error
      token
      data {
        userId
        firstName
        lastName
        email
        roles
        communityCode
      }
    }
  }
`;
type REGISTER_MUTATION_VARS = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  registrationCode: string;
};
type REGISTER_MUTATION_RES = {
  registerUser: backendTokenResponse<{
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    communityCode: string;
  }>;
};

const role = 'resident';
export default function CreateAccount(): ReactElement {
  const [registrationCode, setRegistrationCode] = useState<string>('');
  const [fname, setFname] = useState<string>('');
  const [lname, setLname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [redirect, setRedirect] = useState<string | undefined>();
  const { bigLogo, communityId, featuresConfig } = useContext(CommunityContext);
  const { communityTitle, communitySubTitle, alertText } = useCreateAccountTexts(role);

  const hiddenAlert = () => {
    setTimeout(() => {
      setAlert('');
    }, 4000);
  };

  const redirectUser = () => {
    if (featuresConfig?.host?.enabled && featuresConfig?.resident?.enabled) {
      setRedirect('/create-account/options');
    } else if (featuresConfig?.host?.enabled && !featuresConfig?.resident?.enabled) {
      setRedirect('/resident/host-registration');
    } else if (featuresConfig?.resident?.enabled && !featuresConfig?.host?.enabled) {
      setRedirect('/resident/registration');
      return;
    } else {
      setRedirect('/host-portal');
    }
  };
  const [doRegister] = useMutation<REGISTER_MUTATION_RES, REGISTER_MUTATION_VARS>(
    REGISTER_MUTATION,
    {
      onError: e => {
        setAlert(e.message);
        setAlertColor('red');
        hiddenAlert();
      },
      onCompleted: d => {
        if (d.registerUser.success) {
          store.dispatch({
            type: 'user/login',
            payload: {
              token: d.registerUser.token,
              user: {
                ...d.registerUser.data,
                communityCode: d.registerUser.data?.communityCode,
              },
            },
          });
          redirectUser();
        } else {
          setAlert(d.registerUser.error);
          setAlertColor('red');
          hiddenAlert();
        }
      },
    },
  );

  const submitHandler = () => {
    // const checkedCC = ['resident'].includes(role) ? registrationCode : communityPicker();
    // console.log(checkedCC);
    if ([fname, lname, email, password].some((i: string) => i === '')) {
      setAlert('Please fill out the required info');
      setAlertColor('red');
      hiddenAlert();
    } else if (confirmPassword !== password) {
      setAlert('Those passwords do not match');
      setAlertColor('red');
      hiddenAlert();
    } else {
      doRegister({
        variables: {
          registrationCode,
          firstName: fname,
          lastName: lname,
          email,
          password,
        },
      });
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <form
          className={style.box__left}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            submitHandler();
          }}
        >
          <div className={style.box__titleBoxLeft}>
            <h3 className={`${style.box__mainTitleLeft} textColorDark `}>
              {communityTitle}
            </h3>
            <p className={`${style.box__paragraph} textColorMedium `}>
              {communitySubTitle}
            </p>
            {communityId === 'palmettodunes' ? (
              <p className={`${style.box__importantNotice}`}>{alertText}</p>
            ) : null}
          </div>
          <h5 className={`${style.box__subTitle} textColorDark `}>
            Please create your account
          </h5>
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert color={alertColor} title={alert} hidden={!alert} />
            </div>
          </div>
          {['resident'].includes(role) ? (
            <div className={style.inputLong}>
              <div className={style.box__inputLong}>
                <div className={style.box__inputField}>
                  <InputField
                    smallSize
                    highlightOnFocus
                    htmlFor="comm-code-inpt"
                    labelTitle="Community Code"
                    inputType="text"
                    maxLength={50}
                    inputValue={registrationCode}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setRegistrationCode(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className={style.box__nameBox}>
            <div className={style.box__inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="first-name-inpt"
                maxLength={100}
                labelTitle="First Name"
                inputType="text"
                inputValue={fname}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFname(e.target.value);
                }}
              />
            </div>
            <div className={style.invisbleBox} />
            <div className={style.box__inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="last-name-inpt"
                labelTitle="Last Name"
                inputType="text"
                inputValue={lname}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLname(e.target.value);
                }}
                maxLength={100}
              />
            </div>
          </div>
          <div className={style.inputLong}>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="email-inpt"
                  labelTitle="Email Address"
                  inputType="email"
                  inputValue={email}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                  // I think 320 is the maximum number of characters
                  // most email systems will accept - Parker
                  maxLength={320}
                />
              </div>
            </div>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="password-inpt"
                  labelTitle="Password"
                  inputType="password"
                  inputValue={password}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                  maxLength={100}
                />
              </div>
            </div>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="verify-password-inpt"
                  labelTitle="Verify Password"
                  inputType="password"
                  inputValue={confirmPassword}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmPassword(e.target.value);
                  }}
                  maxLength={100}
                />
              </div>
            </div>
          </div>

          <div className={style.box__btnLeft}>
            <div className={style.box__btnLeftInnerBox}>
              <GenericButton
                color="blue"
                size="medium"
                title="Create Account"
                type="submit"
              />
            </div>
          </div>
        </form>
        <div className={`${style.box__right}  linerGradient `}>
          <img
            src={bigLogo}
            alt=""
            className={
              communityId === 'watercolor' ||
              communityId === 'debordieu' ||
              communityId === 'carillonbeach'
                ? style.box__imgPostion
                : style.box__img
            }
          />
          <h1 className={`${style.box__mainTitleRight} textColorWhite `}>
            <div className={style.box__mainTitleRightInnerBox}>
              Do you already have an account?
            </div>
          </h1>
          <Link to="/login">
            <div className={style.box__btnRight}>
              <div className={style.box__btnRightInnerBox}>
                <GenericButton color="white" size="medium" title="Login" />
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}
