import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import GenericAlert from '../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import PassSummaryCollection from '../../../../common_lib_front/components/passSummary/passSummaryCollection';
import PassFormCollection from '../../../../components/residentPassForm/passFormCollection';
import ResidentPassHeader from '../../../../components/residentPassHeader/residentPassNavHeader';
import style from './registerVehicle.module.css';
import useRegisterVehicle from './useRegisterVehicle';

export function RegisterVehicle(): ReactElement {
  const { redirect, doSubmit, alert, passes, setPasses, t, submitLoading } =
    useRegisterVehicle();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <form
      className={style.container}
      onSubmit={e => {
        e.preventDefault();
        console.log(e);
        doSubmit();
      }}
    >
      <div className={`${style.box} white `}>
        <ResidentPassHeader
          activeNum={1}
          title={`${t('Step 1')}: ${t('Pass Information')}`}
          subtitle={t(
            'Please enter all required information below to register. Failure to do so will result in fines and possible explusion from the community.',
          )}
        />
        <GenericAlert color="red" title={alert} hidden={!alert} />
        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <PassFormCollection
              data={passes}
              setData={setPasses}
              showSaveBtn={false}
              showTitle
            />
          </div>
          <div className={style.boxRight}>
            <PassSummaryCollection
              showEditBtn={false}
              showVehicleInfo={false}
              showTotal
              data={passes}
            />
            <small className={style.smallText}>
              Prices in US dollars(USD), Community access Passes are nonrefundable. Total
              calculated at checkout.
            </small>
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton
            size="large"
            color="blue"
            title={t('Continue')}
            type="submit"
            disabled={submitLoading}
          />
        </div>
      </div>
    </form>
  );
}

export default RegisterVehicle;
