export type EmployeeInfo = {
  employeeId?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  jobTitle?: string;
  employeePictureUrl?: string;
  driversLicenseImageUrl?: string;
  driversLicenseScanUrl?: string;
  identificationSource?: string;
  [key: string]: any;
};

export interface IAddEmployeeInfo {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  jobTitle?: string;
  employeePicture?: string;
  driversLicenseImage?: string;
  driversLicenseScan?: string;
  file?: string;
  fileName?: string;
  identificationNumber?: string;
  isLicenseSelected?: boolean;
  driversLicenseExp?: string;
  identificationSource?: string;
  [key: string]: any;
}

export function newEmployeeInfo(data: any = {}): IAddEmployeeInfo {
  const res = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    employeePicture: '',
    driversLicenseImage: '',
    driversLicenseScan: '',
    file: '',
    fileName: '',
    driversLicenseExp: '',
  };
  Object.assign(res, data);
  if (typeof data.primaryDriverName === 'string' && !res.firstName && !res.lastName) {
    res.firstName = data.primaryDriverName;
  }
  return res;
}

export default EmployeeInfo;
