import React, { ReactElement, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import RegistrationProcessNav from '../../../components/registrationProcessNav/registrationProcessNav';
import style from './registrationCheckOut.module.css';
import useRegistrationSecureCheckout from './useRegistrationCheckOut';

export default function RegistrationSecureCheckout(): ReactElement {
  const { registrationId, registrationData, advanceStep } =
    useRegistrationSecureCheckout();
  const history = useHistory();
  //   const { communityId } = useContext(CommunityContext);
  let checkoutStepNumber = 4;
  const currentCommunityData = useContext(CommunityContext);
  if (
    !currentCommunityData?.featuresConfig?.resident?.registration?.educationStepEnabled
  ) {
    checkoutStepNumber--;
  }
  if (
    !currentCommunityData?.featuresConfig?.resident?.registration?.documentStepEnabled
  ) {
    checkoutStepNumber--;
  }
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationProcessNav
          stepNum={checkoutStepNumber}
          title={'Step ' + checkoutStepNumber + ': Review Information'}
          subtitle="Please verify all information below is correct"
        />
        <form
          className={style.form}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            advanceStep();
            history.replace(`/resident/registration/${registrationId}/thank-you`);
          }}
        >
          <div className={style.flexBox}>
            <div className={style.boxLeft}>
              <div className={style.infoFormBox}>
                <h3
                  className={`${style.reviewTitle} ${style.hidden} textColorSuperDark `}
                >
                  Review Information
                </h3>
                <p className={`${style.subTitle} ${style.hidden} textColorSuperDark `}>
                  Please verify all information below is correct
                </p>
                <div className={`${style.header} superDarkGrey `}>
                  <h5 className={`${style.title} textColorWhite `}>
                    Resident Information
                  </h5>
                  <div className={`${style.btnEdit}`}>
                    <GenericButton
                      title="Edit"
                      color="transparent"
                      outline="no-border"
                      clickHandler={() => {
                        history.replace(
                          `/resident/registration/${registrationId}/information`,
                        );
                      }}
                    />
                  </div>
                </div>
                <table className={style.tableBox}>
                  <tbody>
                    <tr>
                      <td>NAME</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.firstName} {registrationData.lastName}
                      </td>
                    </tr>
                    <tr className={style.lightColor}>
                      <td>COMMUNITY ADDRESS</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}></td>
                    </tr>
                    <tr>
                      <td>EMAIL</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.email}
                      </td>
                    </tr>
                    <tr>
                      <td>STREET</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.street}
                      </td>
                    </tr>
                    <tr>
                      <td>PHONE NUMBER (PRIMARY)</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.phoneNumber}
                      </td>
                    </tr>
                    <tr>
                      <td>APT/UNIT</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.unit}
                      </td>
                    </tr>
                    <tr>
                      <td>PHONE NUMBER (ALTERNATE)</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.altPhoneNum}
                      </td>
                    </tr>

                    <tr>
                      <td>CITY</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.city}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className={`${style.personalInfoDetail} textColorDark `}></td>
                    </tr>
                    <tr>
                      <td>STATE</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.state}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className={`${style.personalInfoDetail} textColorDark `}></td>
                    </tr>
                    <tr>
                      <td>ZIP CODE</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.zipCode}
                      </td>
                    </tr>
                    <tr className={style.lightColor}>
                      <td>MAILING ADDRESS</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}></td>
                    </tr>
                    <tr className={style.lightColor}>
                      <td>EMERGENCY CONTACT</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}></td>
                    </tr>
                    <tr>
                      <td>STREET ADDRESS</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.mailingStreet}
                      </td>
                    </tr>
                    <tr>
                      <td>NAME</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.emergencyFirstName}{' '}
                        {registrationData.emergencyLastName}
                      </td>
                    </tr>
                    <tr>
                      <td>APT/UNIT</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.mailingUnit}
                      </td>
                    </tr>
                    <tr>
                      <td>EMAIL</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.emergencyEmail}
                      </td>
                    </tr>
                    <tr>
                      <td>CITY</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.mailingCity}
                      </td>
                    </tr>
                    <tr>
                      <td>PHONE NUMBER (MOBILE)</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.emergencyPhoneNumber}
                      </td>
                    </tr>
                    <tr>
                      <td>STATE</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.mailingState}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className={`${style.personalInfoDetail} textColorDark `}></td>
                    </tr>
                    <tr>
                      <td>ZIP CODE</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.mailingZipCode}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className={`${style.personalInfoDetail} textColorDark `}></td>
                    </tr>
                    <tr>
                      <td>OWNER PIN</td>
                      <td className={`${style.personalInfoDetail} textColorDark `}>
                        {registrationData.ownerPin}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className={style.documentBtnBox}>
                  <div className={`${style.documentBtn}`}>
                    <GenericButton
                      color="white"
                      outline="large"
                      hidden={
                        !currentCommunityData?.featuresConfig?.resident?.registration
                          ?.documentStepEnabled
                      }
                      title="Edit Documents"
                      clickHandler={() => {
                        history.replace(
                          `/resident/registration/${registrationId}/documents`,
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.btn}>
            <GenericButton
              size="large"
              title="Submit Information"
              color="blue"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
