import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AchIcon } from '../../assets/AchPaymentIcon.svg';
import { ReactComponent as CashIcon } from '../../assets/cashIcon.svg';
import { ReactComponent as CreditCardIcons } from '../../assets/creditCardIcons.svg';
import cardPicAmex from '../../assets/paymentMethodAMEX.svg';
import cardPicDiscover from '../../assets/paymentMethodDiscover.svg';
import cardPicMaster from '../../assets/paymentMethodMaster.svg';
import cardPicVisa from '../../assets/paymentMethodVisa.svg';
import { ReactComponent as WalletIcon } from '../../assets/Wallet.svg';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import InputField from '../../components/inputField/inputField';
import style from './paymentInfoForm.module.css';

interface PaymentInfoFormProps {
  selectedPayment: string;
  setSelectedPayment: (s: string) => void;
}

export default function PaymentInfoForm({
  selectedPayment,
  setSelectedPayment,
}: PaymentInfoFormProps): ReactElement {
  const { t } = useTranslation();
  const { communityId } = useContext(CommunityContext);
  // const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedPayment(event.target.value);
  // };
  return (
    <div className={style.box}>
      <div className={style.infoBox}>
        <div className={style.innerBox}>
          <div className={style.paymentTitle}>
            <div className={style.icon}>
              <WalletIcon />
            </div>
            <h3 className={`${style.title} textColorDark `}>{t('Payment Method')}</h3>
          </div>
          <button
            className={selectedPayment === 'card' ? style.active : style.inactive}
            onClick={() => {
              setSelectedPayment('card');
            }}
          >
            <div className={style.cardIcon}>
              <CreditCardIcons />
            </div>{' '}
            <p className={style.buttonTitle}>{t('Credit Card')}</p>
          </button>
          <div className={style.innerBox}>
            {/* {selectedPayment === 'Credit Card' ? (
              <div className={style.dropDownBox}>
                <h3 className={`${style.methodTitle}  `}>Credit Card</h3>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="card-name-inpt"
                    labelTitle={t('Name on Card')}
                    inputType="text"
                    inputPlaceholder=""
                  />

                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="card-number-inpt"
                    labelTitle={t('Card Number')}
                    inputType="text"
                    inputPlaceholder=""
                  />

                </div>
                <div className={style.inputShortBox}>
                  <div className={style.inputShort}>
                    <InputField
                      closedInput
                      htmlFor="card-exp-inpt"
                      labelTitle={t('EXP. Date')}
                      inputType="text"
                      inputPlaceholder=""
                    />

                  </div>
                  <div className={style.inputShort}>
                    <InputField
                      closedInput
                      htmlFor="card-csv-inpt"
                      labelTitle={t('CSV')}
                      inputType="text"
                      inputPlaceholder=""
                    />

                  </div>
                </div>

              </div>
            ) : null} */}
          </div>

          {/* <button
            className={selectedPayment === 'ACH Payment' ? style.active : style.inactive}
            onClick={() => {
              setSelectedPayment('ACH Payment');
            }}
          >
            <div className={style.cardIcon}>
              <AchIcon />
            </div>
            {' '}
            <p className={style.buttonTitle}>{t('ACH Payment')}</p>
          </button> */}
          <div className={style.innerBox}>
            {/* {selectedPayment === 'ACH Payment' ? (
              <div className={style.dropDownBox}>
                <h3 className={`${style.methodTitle}  `}>ACH Payment</h3>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="ach-number-inpt"
                    labelTitle="Bank Routing Number"
                    inputType="text"
                    inputPlaceholder=""
                  />

                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="ach-number-inpt"
                    labelTitle="Account Number"
                    inputType="text"
                    inputPlaceholder=""
                  />
                </div>

              </div>
            ) : null} */}
          </div>
          <button
            className={
              selectedPayment === 'Cash/Physical Check' ? style.active : style.inactive
            }
            onClick={() => {
              setSelectedPayment('Cash/Physical Check');
            }}
          >
            <div className={style.cardIcon}>
              <CashIcon />
            </div>{' '}
            <p className={style.buttonTitle}>
              {communityId === 'sipoa'
                ? t('Payment by Check')
                : t('Cash/Paper Check/Other at the Pass Office')}
            </p>
          </button>
          {selectedPayment === 'Cash/Physical Check' ? (
            <div className={style.instruction}>
              {communityId === 'sipoa'
                ? t(`Contractors/Vendors may pay by check at the Pass Office. 
                    Gate passes are not valid until payment is rendered.`)
                : t(`Cash/Paper Check/Other: Custom Instruction from Pass Builder show up with 
                    the incomplete pass telling user how to pay with cash.`)}
            </div>
          ) : null}
        </div>
      </div>

      {/* <div className={style.infoBox} /> */}

      <div className={style.cardBox}>
        <div className={style.cardInnerBox}>
          {selectedPayment === 'Cash/Physical Check' ? null : (
            <>
              <h4 className={`${style.cardTitle} textColorSuperDark`}>
                {t('All major credit and debit cards are accepted')}
              </h4>
              <div className={style.imgBox}>
                <img src={cardPicVisa} alt="" className={style.img} />
                <img src={cardPicMaster} alt="" className={style.img} />
                <img src={cardPicDiscover} alt="" className={style.img} />
                <img src={cardPicAmex} alt="" className={style.img} />
              </div>
            </>
          )}
          <p className={`${style.cardDescription} textColorMedium`}>{t('price_info')}</p>
        </div>
      </div>
    </div>
  );
}
