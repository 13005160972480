import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { ReactComponent as HamburgerImg } from '../../assets/hamburger.svg';
import { ReactComponent as HamburgerXImg } from '../../assets/hamburgerClose.svg';
import { CommuntiyConfigType } from '../../common_lib_front/communityConfigs/communitiesRequests';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import { HelpTooltip } from '../../common_lib_front/components/HelpTooltip/HelpTooltip';
import store, {
  isLogedIn as reduxIsLogedIn,
} from '../../common_lib_front/utilities/store';
import useMediaQuery from '../../common_lib_front/utilities/useMediaQuery';
import {
  useGetHostApplication,
  useGetResidentApplication,
} from '../../hooks/useGetApplication';
import UserProfileButton from '../userProfileButton/userProfileButton';
import style from './header.module.css';

type linkShowConfig = {
  communityIdAllowList: string[];
  // show this link in the host portal excluding the home page
  inHost: boolean;
  // show this link in the resident portal excluding the home page
  inResident: boolean;
  // show this link on the host home page
  inHostHome: boolean;
  // show this link on the resident home page
  inResidentHome: boolean;
  // show this link on the create account options page
  inCreateAccountOptions: boolean;
  ifLoggedIn: boolean;
  ifLoggedOut: boolean;
  ifResidentApplicationComplete: boolean;
  ifResidentApplicationNotComplete: boolean;
  ifResidentApplicationApproved: boolean;
  ifHostApplicationComplete: boolean;
  ifHostApplicationApproved: boolean;
  otherConfig: (communityConfig?: CommuntiyConfigType) => boolean;
};
type linkConfig = {
  name: string;
  to: LinkProps['to'];
  show: Partial<linkShowConfig>;
  active: RegExp;
};

const allLinks: linkConfig[] = [
  {
    name: 'My Profile',
    to: '/resident/edit-profile/my-profile',
    active: /\/resident\/edit-profile/,
    show: {
      inResident: true,
      ifLoggedIn: true,
    },
  },
  {
    name: 'My Profile',
    to: { pathname: '/resident/edit-profile/my-profile', search: 'isHost=true' },
    active: /\/resident\/edit-profile/,
    show: {
      inHost: true,
      ifLoggedIn: true,
    },
  },
  {
    name: 'Get a Pass',
    to: '/resident/get-passes',
    active: /\/resident\/get-passes/,
    show: {
      inResident: true,
      ifLoggedIn: true,
      ifResidentApplicationNotComplete: false,
      otherConfig: featuresConfig =>
        Boolean(
          featuresConfig?.resident?.residentPasses?.enabled ||
            featuresConfig?.resident?.inviteVendor?.enabled ||
            featuresConfig?.resident?.familyAndFriendsPasses?.enabled,
        ),
    },
  },
  {
    name: 'My Passes',
    to: '/resident/my-passes',
    active: /\/resident\/my-passes/,
    show: {
      inResident: true,
      ifLoggedIn: true,
      ifResidentApplicationNotComplete: false,
      otherConfig: featuresConfig =>
        Boolean(
          featuresConfig?.resident?.residentPasses?.enabled ||
            featuresConfig?.resident?.inviteVendor?.enabled ||
            featuresConfig?.resident?.familyAndFriendsPasses?.enabled,
        ),
    },
  },
  {
    name: 'Share a Single-Use Pass',
    to: '/resident/share-pass',
    active: /\/resident\/share-pass/,
    show: {
      inResident: true,
      ifLoggedIn: true,
      otherConfig: featuresConfig => Boolean(featuresConfig?.legacySingleUsePassEnabled),
    },
  },
  {
    name: 'Share a Single-Use Vendor Access Pass',
    to: '/resident/share-pass',
    active: /\/resident\/share-pass/,
    show: {
      inResident: true,
      inHost: true,
      ifLoggedIn: true,
      otherConfig: featuresConfig => Boolean(featuresConfig?.legacySingleUsePassEnabled),
    },
  },
  {
    name: 'Owner Gate Code',
    to: '/resident/owner-code',
    active: /\/resident\/owner-code/,
    show: {
      inResident: true,
      otherConfig: communityConfig => Boolean(communityConfig?.gateCode),
      ifLoggedIn: true,
    },
  },
  {
    name: 'Short-Term Rental Registration',
    to: '/resident/rental-registration',
    active: /\/resident\/rental-registration/,
    show: {
      inHost: true,
      otherConfig: communityConfig => Boolean(communityConfig?.host?.enabled),
      ifLoggedIn: true,
    },
  },
  {
    name: 'My Rentals',
    to: '/resident/my-rentals',
    active: /\/resident\/my-rentals/,
    show: {
      inHost: true,
      otherConfig: communityConfig => Boolean(communityConfig?.host?.enabled),
      ifLoggedIn: true,
    },
  },
  {
    name: 'Invite a Rental Guest',
    to: '/invite-guest',
    active: /\/invite-guest/,
    show: {
      inHost: true,
      ifLoggedIn: true,
    },
  },
  {
    name: 'Host Portal',
    to: '/host-portal',
    active: /\/host-portal(\/)?/,
    show: {
      inResident: true,
      ifHostApplicationApproved: true,
      ifLoggedIn: true,
    },
  },
  {
    name: 'To Resident Portal',
    to: '/resident',
    active: /\/resident(\/)?$/,
    show: {
      inHost: true,
      ifResidentApplicationComplete: true,
      ifLoggedIn: true,
    },
  },
];

function HeaderLink(props: { cfg: linkConfig }): React.ReactElement {
  const { cfg } = props;
  const { pathname } = useLocation();

  const active = useMemo(() => cfg.active.test(pathname), [pathname, cfg.active]);

  return (
    <div className={`${style.navItemBox}  textColorMain `}>
      <Link
        to={cfg.to}
        className={`${style.navItem} textColorWhite ${active ? style.clicked : ''}`}
      >
        {cfg.name}
      </Link>
      <div className={`${active ? 'mainColor' : ''} ${style.navLine}`} />
    </div>
  );
}

export function Header(): React.ReactElement {
  const [isLogedin, setIsLogedin] = useState<boolean>(reduxIsLogedIn());
  useEffect(
    () =>
      store.subscribe(() => {
        setIsLogedin(reduxIsLogedIn());
      }),
    [setIsLogedin],
  );

  const {
    communityId,
    name: communityName,
    logo,
    featuresConfig: communityConfig,
    zendeskURLs,
  } = useContext(CommunityContext);

  const { pathname, search } = useLocation();
  const [showLinks, setShowLinks] = useState<boolean>(false);
  const smallScreen = useMediaQuery('(max-width: 767px)');

  // get host and resident applications
  const { data: hostApplication, refetch: refetechHostApplicationCall } =
    useGetHostApplication();
  const { data: residentApplication, refetch: refetchResidentApplicationCall } =
    useGetResidentApplication();
  // parse host application status
  const hostApplicationStatus = useMemo(() => {
    return hostApplication?.getApplication.data?.status;
  }, [hostApplication]);

  // parse resident application status
  const residentApplicationStatus = useMemo(() => {
    return residentApplication?.getApplication.data?.status;
  }, [residentApplication]);

  // url regex to determine if in host
  const inHost =
    /\/(invite-guest|my-rentals|rental-registration|host-registration|host-portal)/.test(
      pathname,
    ) || new URLSearchParams(search).get('isHost') === 'true';
  // url regex to determine if in host
  const inHostHome = /\/(host-portal(\/)?$)/.test(pathname);
  const inResident = !inHost;
  const inCreateAccountOptions = /\/create-account\/options(\/)?$/.test(pathname);
  // url regex to determine if in resident home
  const inResidentHome = /\/(resident(\/)?$)/.test(pathname);

  const logoRoute = useMemo(() => {
    if (!isLogedin) {
      return '/login';
    }
    if (inHostHome && residentApplicationStatus) {
      return '/resident';
    }
    return inHost ? '/host-portal' : '/resident';
  }, [isLogedin, inHost, inHostHome, residentApplicationStatus]);

  useEffect(() => {
    refetechHostApplicationCall();
    refetchResidentApplicationCall();
  }, [logoRoute]);

  const filteredLinks = allLinks.filter((cfg): boolean => {
    if (cfg.show?.ifLoggedIn && !isLogedin) return false;
    if (cfg.show?.ifLoggedOut && isLogedin) return false;
    if (
      cfg.show?.communityIdAllowList &&
      !cfg.show.communityIdAllowList.includes(communityId)
    )
      return false;
    if (cfg.show?.inHost !== true && inHost === true) return false;
    if (cfg.show?.inHostHome !== true && inHostHome === true) return false;
    if (cfg.show?.inResident !== true && inResident === true) return false;
    if (cfg.show?.inResidentHome !== true && inResidentHome === true) return false;
    if (cfg.show?.inCreateAccountOptions !== true && inCreateAccountOptions === true)
      return false;
    if (cfg.show.ifHostApplicationApproved && hostApplicationStatus !== 'approved')
      return false;
    if (cfg.show.ifHostApplicationComplete && hostApplicationStatus === 'incomplete')
      return false;
    if (
      cfg.show.ifResidentApplicationApproved &&
      residentApplicationStatus !== 'approved'
    )
      return false;
    if (
      cfg.show.ifResidentApplicationComplete &&
      !['pending-review', 'approved', 'stalled'].includes(residentApplicationStatus || '')
    )
      return false;
    if (
      cfg.show.ifResidentApplicationNotComplete &&
      residentApplicationStatus !== 'incomplete'
    )
      return false;
    if (cfg.show.otherConfig && !cfg.show.otherConfig(communityConfig)) return false;
    return true;
  });

  let headerStyle;
  let invisiableBox;
  let logoBox;
  let logoLayout: string;
  let profileBox;

  if (showLinks) {
    // TODO: set styles for when navigation links are displayed
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
  } else {
    // TODO: set styles for when navigation links are not shown
    headerStyle = style.headerLogoMiddle;
    invisiableBox = style.invisibleBoxApper;
    logoBox = style.logoBoxLarge;
    logoLayout = style.logoMiddle;
    profileBox = style.profileLogoMiddle;
  }

  if (filteredLinks.length > 0) {
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
    invisiableBox = style.invisibleBoxDisApper;
  } else {
    headerStyle = style.headerLogoMiddle;
    invisiableBox = style.invisibleBoxApper;
    logoBox = style.logoBoxLarge;
    logoLayout = style.logoMiddle;
    profileBox = style.profileLogoMiddle;
  }
  return (
    <div className={`${headerStyle} white `}>
      {/* small screen hamburger menu */}
      {smallScreen && filteredLinks.length > 0 ? (
        <div className={style.hamburgerBtn}>
          <GenericButton
            title=""
            outline="none"
            color="none"
            icon={() =>
              showLinks ? (
                <HamburgerXImg className={`${style.hamburgerCloseImg}`} />
              ) : (
                <HamburgerImg className={`${style.hamburgerImg}`} />
              )
            }
            clickHandler={() => setShowLinks(!showLinks)}
          />
        </div>
      ) : null}
      {/* text to indicate host or resident portal home pages */}
      <div className={invisiableBox}>
        {isLogedin && (inHostHome || inResidentHome) && (
          <div className={style.portalTextBox}>
            <div className={style.portalText}>
              <p className={'textColorMain'}>
                {inResident ? 'Resident Portal' : 'Host / Rental Management Portal'}
              </p>
            </div>
          </div>
        )}
      </div>
      {/* logo or community name depending on screen size. Link if available */}
      {logoRoute && !inCreateAccountOptions ? (
        <Link to={logoRoute} className={logoBox}>
          {smallScreen ? (
            communityName
          ) : (
            <img src={logo} alt="logo" className={logoLayout} />
          )}
        </Link>
      ) : (
        <div className={logoBox}>
          <img src={logo} alt="logo" className={logoLayout} />
        </div>
      )}
      {/* only render if there are links to show. If it is a small screen, only render if the menu is open */}
      {filteredLinks.length > 0 && (!smallScreen || showLinks) ? (
        <nav className={style.nav}>
          {filteredLinks.map(cfg => (
            <HeaderLink cfg={cfg} key={cfg.to.toString()} />
          ))}
        </nav>
      ) : null}
      <div className={profileBox}>
        {/* to resident portal link */}
        {isLogedin && inHostHome && residentApplicationStatus && (
          <div className={style.toResidentTxtBox}>
            <div className={style.toResidentTxt}>
              <Link to="/resident">
                <p className={'textColorMain'}>To Resident Portal</p>
              </Link>
            </div>
          </div>
        )}
        <div className={style.profileBtnApper}>
          {/* user profile button */}
          {isLogedin && !inCreateAccountOptions && <UserProfileButton isHost={inHost} />}
          {Boolean(zendeskURLs?.resident) ? (
            <Link to="/help">
              <p
                className={`${
                  isLogedin ? style.helpBtnApper : style.helpBtnHorizontalCenter
                } textColorMain`}
              >
                Help
              </p>
            </Link>
          ) : (
            <div>
              <HelpTooltip>
                <Link to="https://symliv.com/get-in-touch">
                  <p
                    className={`${
                      isLogedin ? style.helpBtnApper : style.helpBtnHorizontalCenter
                    } textColorMain`}
                  >
                    Help
                  </p>
                </Link>
              </HelpTooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
