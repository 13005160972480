import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import GenericAlert from '../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import ResidentPassHeader from '../../../../components/residentPassHeader/residentPassNavHeader';
import style from './passSelection.module.css';
import usePassSelection from './usePassSelection';

export default function PassSelection(): ReactElement {
  const { passGroups, passTypes, isSelected, setQuantity, incQuantity, saveSelected } =
    usePassSelection();
  const { registrationId } = useParams<{ registrationId: string }>();
  const [redirect, setRedirect] = useState<string>('');

  const { t } = useTranslation();

  const doSubmit = async () => {
    saveSelected();
    setRedirect(`/resident/get-passes/resident-pass/${registrationId}/vehicle`);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <ResidentPassHeader
          title={t('Pass Selection')}
          subtitle={t('Please select passes below')}
        />

        {passGroups?.length === 0 && (
          <GenericAlert
            title="No passes for this community are available at the moment"
            color="red"
          />
        )}
        <div className={style.passContainer}>
          {passTypes.map(passInfo => (
            <div className={style.passInfo} key={passInfo.passInfoId}>
              <div className={style.passInfoName}>
                <h4 className={style.passType}>{t('Pass Type:')}</h4>
                <h4 className={style.passName}>{passInfo.name}</h4>
                <div className={style.passDescriptionBox}>
                  <div className={style.passDescription}>{passInfo.description}</div>
                </div>
              </div>
              <div className={style.priceInfo}>
                <div className={style.priceInfoLabel}>
                  {t('Price: ')}
                  <span className={style.price}>
                    ${passInfo?.priceInfo?.price}
                    .00
                  </span>
                </div>
              </div>
              <div className={style.passNumberBox}>
                <label className={style.label} htmlFor="number-select">
                  {t('Number of Passes')}
                  <div className={style.numberInputBox}>
                    <button
                      className={style.btnLeft}
                      onClick={() => incQuantity(passInfo, -1)}
                    >
                      -
                    </button>
                    <input
                      id="number-select"
                      type="number"
                      className={style.numberInput}
                      value={passInfo.quantity || 0}
                      onChange={event =>
                        setQuantity(passInfo, parseInt(event.target.value, 10))
                      }
                    />
                    <button
                      className={style.btnRight}
                      onClick={() => incQuantity(passInfo, 1)}
                    >
                      +
                    </button>
                  </div>
                </label>
              </div>
            </div>
          ))}
        </div>

        <div className={style.btn}>
          <GenericButton
            size="large"
            color="blue"
            title={t('Continue')}
            disabled={!isSelected()}
            clickHandler={doSubmit}
          />
        </div>
      </div>
    </div>
  );
}
