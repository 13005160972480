import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CommunityContext } from '../../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import PassSummaryCollection from '../../../../common_lib_front/components/passSummary/passSummaryCollection';
import useOrderPrice from '../../../../common_lib_front/hooks/useOrderPrice';
import PassInfo from '../../../../common_lib_front/types/passInfo';
import { passCredOrNumber } from '../../../../common_lib_front/utilities/passCredOrNumber';
import { exportPdf } from '../../../../common_lib_front/utilities/pdf';
import ResidentPassHeader from '../../../../components/residentPassHeader/residentPassNavHeader';
import style from './orderSummary.module.css';
import useOrderSummary from './useOrderSummary';

export default function OrderSummary(): ReactElement {
  const { passes, errorMsg } = useOrderSummary();
  const { communityId } = useContext(CommunityContext);

  const { t } = useTranslation();
  const nowDate = new Date();

  const { totalPrice } = useOrderPrice(passes);

  const downloadPdf = async () => {
    const pdf: any = await exportPdf(passes.map(p => p.url));
    if (pdf) {
      pdf.save('access-passes.pdf');
    }
  };

  const printPdf = async () => {
    const pdf: any = await exportPdf(passes.map(p => p.url));
    if (pdf) {
      const blob = pdf.output('blob');
      const nextWindow = window.open(URL.createObjectURL(blob), '_blank');
      nextWindow?.focus();
      nextWindow?.print();
    }
  };

  const history = useHistory();
  const alert = errorMsg;
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <ResidentPassHeader
          activeNum={4}
          title={t('Thank you. We hope you enjoy your visit!')}
          subtitle={t('')}
        />
        <GenericAlert color="red" title={alert} hidden={!alert} />

        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <div className={style.infoBox}>
              <div className={style.innerInfoBox}>
                <h3 className={`${style.title} textColorSuperDark `}>
                  {t('Order Summary')}
                </h3>
                <p className={`${style.text} textColorSuperDark `}>
                  {t('A confirmation email has been sent to your email')}{' '}
                  {t('along with your Community Access Pass')} {communityId === 'sipoa'}
                </p>
                <ul>
                  <li>
                    <b>{t('Community Pass Number')}: </b>
                    <ul>
                      {passes.map((pass: PassInfo) => (
                        <li
                          key={passCredOrNumber(pass) + 'pass_num'}
                          className={style.inner_li}
                        >
                          <span>{passCredOrNumber(pass)}</span>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <b>{t('Date')}: </b>
                    <span>{nowDate.toLocaleDateString('en-US')}</span>
                  </li>
                  <li>
                    <b>{t('Purchase Total')}: </b>
                    <span>${totalPrice}</span>
                  </li>
                  <li>
                    <b>{t('Payment Method')}: </b>
                    <span>N/A</span>
                  </li>
                </ul>
                <div className={style.btnBox}>
                  <div className={style.btn}>
                    <GenericButton
                      color="blue"
                      outline="large"
                      title={'Print Pass'}
                      clickHandler={() => {
                        printPdf();
                      }}
                    />
                  </div>
                  <div className={style.btn}>
                    <GenericButton
                      size="large"
                      color="blue"
                      title={'Share Pass'}
                      clickHandler={() => {
                        history.push('/resident/my-passes');
                      }}
                    />
                  </div>
                  <div className={style.btn}>
                    <GenericButton
                      size="large"
                      color="blue"
                      title={'Download Access Pass'}
                      clickHandler={() => {
                        downloadPdf();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="export-pdf-container" className={style.passList}>
              {passes?.map((p: PassInfo) => (
                <div className={`${style.infoBox}`} key={p.passId}>
                  <img src={p.url} className={style.passImage} alt="" />
                </div>
              ))}
            </div>

            {/* <div className={style.instruction}>Custom Instruction from pass builder...</div> */}
          </div>

          <div className={style.boxRight}>
            <PassSummaryCollection
              showEditBtn={false}
              showVehicleInfo
              showTotal
              data={passes}
            />
          </div>
        </div>
        <div className={style.btnContinue}>
          <GenericButton
            size="large"
            color="blue"
            title="Continue"
            clickHandler={() => history.push('/resident/my-passes')}
          />
        </div>
      </div>
    </div>
  );
}
