import { gql, useQuery } from '@apollo/client';
import React, { ReactElement, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { rentalUnitInfo } from '../../../common_lib_front/types/rentalUnitInfo';
import HostPassSummary from '../../../components/hostPassSummary/hostPassSummary';
import RentalRegistrationNav from '../../../components/rantalRegistrationNav/rentalRegistrationNav';
import RentalPass from '../../../components/rentalPass/rentalPass';
import style from './receipt.module.css';

const RECEIPT_QUERY = gql`
  query ReceiptQuery($registrationId: String!) {
    getHostInfoData(registrationId: $registrationId) {
      success
      error
      data {
        applicationDate
      }
    }
    getRentalByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        rentalUnitId
      }
    }
  }
`;
type RECEIPT_QUERY_VARS = {
  registrationId: string;
};
type RECEIPT_QUERY_RES = {
  getHostInfoData: backendResponse<{
    applicationDate: string;
  }>;
  getRentalByRegistration: backendResponse<
    {
      rentalUnitId: string;
      price: string;
    }[]
  >;
};

export default function RentalReceipt(): ReactElement {
  const { registrationId } = useParams<{ registrationId: string }>();
  // const location = useLocation<any>();
  const rentalData = JSON.parse(sessionStorage.getItem('checkoutPassesList') || '[]');
  const { name: communityName, communityId } = useContext(CommunityContext);

  const { data } = useQuery<RECEIPT_QUERY_RES, RECEIPT_QUERY_VARS>(RECEIPT_QUERY, {
    variables: { registrationId },
  });

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RentalRegistrationNav
          stepNum={3}
          title={`Thank you for submitting your ${
            communityId === 'sipoa' ? '' : 'short-term '
          }rental 
          registration at ${communityName}!`}
          subtitle=""
        />
        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <div className={style.infoBox}>
              <h3 className={`${style.title} textColorSuperDark `}>
                Registration Summary
              </h3>
              <p className={`${style.text} textColorSuperDark `}>
                A registration confirmation message has been sent to your email.
              </p>
              <table className={style.tableBox}>
                <tr>
                  <th>Registration Number:</th>
                  <td>
                    <ul>
                      <li className={style.passNum}>{registrationId}</li>
                    </ul>
                  </td>
                </tr>
                {communityId === 'palmettodunes' ? (
                  <div className={`${style.summaryText} textColorSuperDark`}>
                    <p className={style.justifyCenter}>
                      Thank you for registering your short-term rental property. Our team
                      will review your registration form, and you will receive an email
                      regarding its status. If you have received a statement for the
                      Annual Transportation Fee, you can pay it online by logging into the
                      PDPOA Online Payment Portal.
                    </p>
                    <br />
                    <div className={style.linkBox}>
                      <a
                        href="https://pdpoa.appfolio.com/connect/users/sign_in"
                        className={style.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://pdpoa.appfolio.com/connect/users/sign_in
                      </a>
                    </div>
                    <br />
                    <p className={style.justifyCenter}>
                      Please note that if your property is rented through a property
                      management company, they have been charged the Transportation Fee.
                    </p>
                  </div>
                ) : (
                  <div className={`${style.summaryText} textColorSuperDark`}>
                    Thank you for registering your{' '}
                    {`${communityId === 'sipoa' ? ' ' : 'short-term '}`}
                    rental property.
                  </div>
                )}
                <tr className={style.hidden}>
                  <th>Purchase Total:</th>
                  <td>
                    {(rentalData || data?.getRentalByRegistration?.data)?.reduce(
                      (sum: number, r: rentalUnitInfo) =>
                        sum + Number.parseFloat(r.price || '0'),
                      0,
                    )}
                  </td>
                </tr>
                <tr className={style.hidden}>
                  <th>Payment Method:</th>
                  <td>Card or PayPal</td>
                </tr>
              </table>
              {/* <div className={style.btnPrint}>
                <Link to={{
                  pathname: `/resident/host-registration/${registrationId}/receipt/download`,
                  state: { rentalData: rentalData || data?.getRentalByRegistration?.data },
                }}
                >
                  <GenericButton
                    outline="large"
                    title="Download Registration"
                  />
                </Link>

              </div> */}
            </div>
            {communityId === 'palmettodunes' ? (
              <div className={style.rentalPassBox}>
                {(rentalData || data?.getRentalByRegistration?.data).map(
                  (p: rentalUnitInfo) => (
                    <RentalPass key={p.rentalUnitId} rental={p} />
                  ),
                )}
              </div>
            ) : null}
          </div>
          <div className={`${style.boxRight} ${style.hidden}`}>
            <HostPassSummary registrationId={registrationId} />
          </div>
        </div>

        <div className={style.btn}>
          <Link to="/resident/my-rentals">
            <GenericButton size="large" color="blue" title="Continue" />
          </Link>
        </div>
      </div>
    </div>
  );
}
