import { gql, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { CommunityContext } from '../../../../common_lib_front/communityConfigs/communityContextProvider';
import store from '../../../../common_lib_front/utilities/store';

export const GET_PASS_GROUP = gql`
  query getPassGroups($communityId: String!) {
    getPassGroups(communityId: $communityId) {
      success
      error
      data {
        communityId
        passInfoList
        passGroupId
        name
      }
    }
  }
`;

export const GET_ALL_PASS = gql`
  query getPassInfosByCommunity($communityId: String!, $complete: Boolean!) {
    getPassInfosByCommunity(communityId: $communityId, complete: $complete) {
      success
      error
      data {
        passInfoId
        communityId
        portal
        complete
        name
        priceInfo {
          price
        }
        description
        educationRequired
      }
    }
  }
`;

export type GroupType = {
  communityId: string;
  passInfoList: string[];
  passGroupId: string;
  name: string;
};

export type PassType = {
  passInfoId: string;
  communityId: string;
  portal: string;
  complete: boolean;
  name: string;
  description: string;
  priceInfo: { price: number };
  educationRequired: string;
  quantity: number;
};

type usePassSelectionRes = {
  passGroups: GroupType[];
  passTypes: PassType[];
  selectedPassTypes: PassType[];
  setQuantity: (passType: PassType, quantity: number) => void;
  incQuantity: (passType: PassType, amount: number) => void;
  saveSelected: () => void;
  removeSelected: () => void;
  isSelected: () => boolean;
};

export default function usePassSelection(): usePassSelectionRes {
  const { communityId } = useContext(CommunityContext);
  const [passTypes, setPassTypes] = useState<Array<PassType>>([]);
  const [selectedPassTypes, setSelectedPassTypes] = useState<PassType[]>(
    store.getState().selectedPassTypes,
  );

  const { data: groupData } = useQuery(GET_PASS_GROUP, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
    },
  });

  useQuery(GET_ALL_PASS, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
      complete: true,
    },
    onCompleted: async (allPassData: any) => {
      try {
        const passList: any = allPassData?.getPassInfosByCommunity.data?.filter(
          (ele: any) => ele.portal === 'resident',
        );
        setPassTypes(
          passList.map((passType: PassType) => {
            const selected = selectedPassTypes?.filter(
              item => item.passInfoId === passType.passInfoId,
            )[0];
            const updatedObject = { ...passType, quantity: +selected?.quantity || 0 };
            passType = updatedObject;
            return passType;
          }),
        );
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  const setQuantity = (passType: PassType, quantity: number): void => {
    if (!Number.isNaN(quantity) && quantity >= 0) {
      // eslint-disable-next-line no-param-reassign
      passType.quantity = quantity;
    }

    setPassTypes([...passTypes]);
  };

  const incQuantity = (passType: PassType, amount: number): void => {
    setQuantity(passType, passType.quantity + amount);
  };

  const saveSelected = () => {
    const lstIds = passTypes
      .filter((passType: PassType): boolean => passType.quantity > 0)
      .map(obj => obj.passInfoId);
    store.dispatch({
      type: 'SAVE_SELECTED',
      payload: {
        selectedPasses: passTypes.filter(
          (passType: PassType): boolean => passType.quantity > 0,
        ),
        // documentsConfig: result,
      },
    });

    setSelectedPassTypes(store.getState().selectedPassTypes);
  };

  const removeSelected = () => {
    store.dispatch({
      type: 'REMOVE_SELECTED',
      payload: [],
    });
  };

  const isSelected = (): boolean =>
    passTypes.filter((passType: PassType): boolean => passType.quantity > 0).length !== 0;

  return {
    passGroups: groupData?.getPassGroups.data,
    passTypes,
    selectedPassTypes,
    setQuantity,
    incQuantity,
    saveSelected,
    removeSelected,
    isSelected,
  };
}
