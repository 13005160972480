import {
  ApolloCache,
  DefaultContext,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useContext, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';
import PassInfo, {
  newPassInfo,
  PassStatuses,
} from '../../../../common_lib_front/types/passInfo';
import { newVehicleInfo } from '../../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import { passCredOrNumber } from '../../../../common_lib_front/utilities/passCredOrNumber';
import { passFormInput } from '../../../../components/residentPassForm/passForm';
import usePassSelection from '../passSelection/usePassSelection';

const STEP1_QUERY = gql`
  query Step1($registrationId: String!) {
    getRegistration(registrationId: $registrationId) {
      success
      error
      data {
        registrationId
        complete
        renew
      }
    }
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        passInfoId
        number
        externalCredentialNumber
        passType
        startDate
        endDate
        status
        addons
      }
    }
  }
`;

type STEP1_QUERY_RES = {
  getRegistration: backendResponse<
    {
      registrationId: string;
      complete: boolean;
      renew: boolean;
    }[]
  >;

  getPassesByRegistration: backendResponse<
    {
      passId: string;
      passInfoId: string;
      number: string;
      passType: string;
      startDate: string;
      endDate: string;
      status: PassStatuses;
      addons: string[];
      vehicle?: {
        vehicleId: string;
        make: string;
        vehicleModel: string;
        year: number;
        type: string;
        color: string;
        licensePlate: string;
        fleetNumber: string;
        licensePlateState: string;
        primaryDriverName: string;
        licensePrimaryDriver: string;
      } | null;
    }[]
  >;
};

const STEP_TWO_SUMBIT = gql`
  mutation Step1($stepNumber: Float!, $registrationId: String!) {
    editRegistrationStepNumber(
      stepNumber: $stepNumber
      registrationId: $registrationId
      registrationType: "residentPass"
    ) {
      success
      error
    }
  }
`;

const SAVE_PASS = gql`
  mutation SavePass(
    $passType: String!
    $registrationId: String!
    $passInfoId: String!
    $status: String!
    $number: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $addons: [String!]
  ) {
    registerPass(
      passType: $passType
      registrationId: $registrationId
      passInfoId: $passInfoId
      status: $status
      number: $number
      startDate: $startDate
      endDate: $endDate
      addons: $addons
    ) {
      success
      error
      data {
        passId
      }
    }
  }
`;

const SAVE_VEHICLE = gql`
  mutation SaveVehicle(
    $passId: String!
    $make: String
    $vehicleModel: String
    $year: Float
    $type: String
    $color: String
    $licensePlate: String
    $fleetNumber: String
    $licensePlateState: String
    $primaryDriverName: String
    $licensePrimaryDriver: String
  ) {
    registerVehicle(
      passId: $passId
      make: $make
      vehicleModel: $vehicleModel
      year: $year
      type: $type
      color: $color
      licensePlate: $licensePlate
      fleetNumber: $fleetNumber
      licensePlateState: $licensePlateState
      primaryDriverName: $primaryDriverName
      licensePrimaryDriver: $licensePrimaryDriver
    ) {
      success
      error
    }
  }
`;

type SAVE_VEHICLE_VARS = {
  make: string;
  vehicleModel: string;
  year?: number;
  type: string;
  color: string;
  licensePlate: string;
  fleetNumber: string;
  passId: string;
  licensePlateState?: string;
  primaryDriverName?: string;
  licensePrimaryDriver?: string;
};

const DELETE_PASS = gql`
  mutation DeletePassAndVehicle($passId: String!, $vehicleId: String!) {
    deletePass(passId: $passId) {
      success
      error
    }
    deleteVehicle(vehicleId: $vehicleId) {
      success
      error
    }
  }
`;

export const GET_PRICE_INFO = gql`
  query GetPriceInfo($passInfoId: String!) {
    getPriceInfo(passInfoId: $passInfoId) {
      success
      error
      data {
        passInfoId
        isFree
        price
        chargePerDay
        discount
        discountDuration
        discountPrice
        discountPurchasedPasses
        discountPurchasedPassesRanges
        discountPurchasedPassesPrices
        addons {
          name
          isFree
          price
        }
      }
    }
  }
`;

type useRegisterVehicleRes = {
  redirect: string;
  doSubmit: () => Promise<void>;
  alert: string;
  passes: Array<passFormInput>;
  setPasses: React.Dispatch<React.SetStateAction<passFormInput[]>>;
  submitLoading: boolean;
  doRemovePass: (
    options?:
      | MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>>
      | undefined,
  ) => Promise<unknown>;
  // registrationDates: { startDate: string, endDate: string } | null,
  t: TFunction;
};

const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        isRental
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
      }
    }
  }
`;

export default function useRegisterVehicle(): useRegisterVehicleRes {
  const [passes, setPasses] = useState<Array<passFormInput>>([]);
  const { communityId } = useContext(CommunityContext);
  const [redirect, setRedirect] = useState<string>('');
  const { registrationId } = useParams<{ registrationId: string }>();
  const [alert, setAlert] = useState<string>('');
  let { selectedPassTypes } = usePassSelection();
  const { t } = useTranslation();
  const [doRemovePass] = useMutation(DELETE_PASS);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useQuery<STEP1_QUERY_RES>(STEP1_QUERY, {
    variables: { registrationId },
    fetchPolicy: 'no-cache',
    onCompleted: async d => {
      let renew = false;

      if (d.getRegistration.success && d.getRegistration.data) {
        renew = d.getRegistration.data[0].renew;
      }

      const res = d.getPassesByRegistration.data?.map(p => newPassInfo(p as any)) || [];
      Promise.all(
        res.map((element, idx: number) =>
          backendClient
            .query({
              query: GET_VEHICLE,
              variables: { passId: element.passId },
            })
            .then(v => {
              res[idx].vehicle = newVehicleInfo(v.data.getVehicle.data);
            })
            .catch(e => {
              console.log(e);
            }),
        ),
      ).then(() => {
        // setPasses(res);
        // setCompanyInfo(newCompanyInfo(d.getUserInfo.data));
      });

      if (renew) {
        selectedPassTypes = [];
        for (let i = 0; i < res.length; i += 1) {
          const passType = {
            name: res[i].passType,
            passInfoId: res[i].passInfoId,
            quantity: 0,
            communityId: '',
            portal: '',
            complete: true,
            description: '',
            priceInfo: { price: 0 },
            educationRequired: '',
          };

          let found = false;
          // eslint-disable-next-line no-restricted-syntax
          for (const p of selectedPassTypes) {
            if (res[i].passInfoId === p.passInfoId) {
              p.quantity += 1;
              found = true;
              break;
            }
          }
          if (!found) selectedPassTypes.push(passType);
        }
      }

      // cached for later
      localStorage.setItem('remotePasses', JSON.stringify(res));
      // populate initial passes
      if (res.length === 0) {
        selectedPassTypes?.forEach(pass => {
          for (let i = 0; i < pass.quantity; i += 1) {
            res.push(
              newPassInfo({
                // startDate: formatDate(defaultDates.startDate),
                // endDate: formatDate(defaultDates.endDate),
                // passType: pass.name,
                passType: 'resident',
                passInfoId: pass.passInfoId,
              }),
            );
          }
        });
      }
      setPasses(res);
    },
    onError: e => {
      // console.log('error message ===>', e.message);
    },
  });

  const [doSubmitStep1] = useMutation(STEP_TWO_SUMBIT, {
    onError: e => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        setRedirect(
          `/resident/get-passes/resident-pass/${
            registrationId || 0
          }/community-orientation`,
        );
      }
    },
    onCompleted: d => {
      setRedirect(
        `/resident/get-passes/resident-pass/${registrationId}/community-orientation`,
      );
    },
  });

  async function submitInner(p: passFormInput): Promise<any> {
    return backendClient
      .mutate({
        mutation: SAVE_PASS,
        variables: {
          passType: 'resident',
          registrationId,
          status: p.status,
          number: passCredOrNumber(p),
          communityId,
          passInfoId: p.passInfoId,
          startDate: p.startDate,
          endDate: p.endDate,
          addons: p.addons,
        },
      })
      .then(d => {
        backendClient.mutate<any, SAVE_VEHICLE_VARS>({
          mutation: SAVE_VEHICLE,
          variables: {
            passId: d.data.registerPass.data[0].passId,
            make: p.vehicle.make || '',
            vehicleModel: p.vehicle.model || '',
            year: p.vehicle.year,
            type: p.vehicle.type || '',
            color: p.vehicle.color || '',
            licensePlate: p.vehicle.licensePlate || p.vehicle.plateNumber || '',
            fleetNumber: p.vehicle.fleetNumber || '',
            licensePlateState: p.vehicle.licensePlateState || '',
            // primaryDriverName: p.vehicle.primaryDriverName || '',
            primaryDriverName: p.employee
              ? `${p.employee.firstName} ${p.employee.lastName}`
              : p.vehicle.primaryDriverName || '',
            licensePrimaryDriver:
              p.employee?.isLicenseSelected === false
                ? ''
                : p.employee?.driversLicenseNum || p.vehicle.licensePrimaryDriver || '',
          },
        });
      });
  }

  const doSubmit = async () => {
    try {
      setSubmitLoading(true);
      const resStr = localStorage.getItem('remotePasses');
      if (resStr) {
        const res = JSON.parse(resStr);
        res.forEach((resItem: PassInfo) => {
          backendClient
            .mutate({
              mutation: DELETE_PASS,
              variables: {
                passId: resItem.passId,
                vehicleId: resItem.vehicle.vehicleId,
              },
            })
            .catch(err => {
              console.log(`pass save error ${err}`);
              setAlert(
                t(
                  'Some data may not have been saved. Please try again later or report this issue',
                ),
              );
            });
        });
      }
      Promise.all(passes.map(p => submitInner(p)))
        .then(() => {
          if ((window as any).registrationLockStepNum >= 3) {
            setRedirect(
              `/resident/get-passes/resident-pass/${registrationId}/community-orientation`,
            );
            return;
          }
          doSubmitStep1({
            variables: {
              registrationId,
              stepNumber: 1,
            },
          });
        })
        .catch(error => {
          console.log(`pass save error ${error}`);
          setAlert(
            t(
              'Some data may not have been saved. Please try again later or report this issue',
            ),
          );
        });
    } catch (err) {
      console.error(err);
      setAlert(
        t(
          'Some data may not have been saved. Please try again later or report this issue',
        ),
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  return {
    redirect,
    doSubmit,
    alert,
    passes,
    setPasses,
    doRemovePass,
    submitLoading,
    t,
  };
}
