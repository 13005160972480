import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import ScormWrapper from '../../../common_lib_front/components/scormWrapper/scormWrapper';
import RegistrationProcessNav from '../../../components/registrationProcessNav/registrationProcessNav';
import style from './registrationCommunityOrientation.module.css';

const EDIT_STEP_NUMBER = gql`
  mutation EditRegistrationStepNumber($registrationId: String!) {
    editRegistrationStepNumber(
      stepNumber: 3
      registrationId: $registrationId
      registrationType: "resident"
    ) {
      success
      error
    }
  }
`;

export default function RegistrationCommunityOrientation(): ReactElement {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [doNextStep] = useMutation(EDIT_STEP_NUMBER);
  const history = useHistory();
  const [showBackup, setShowBackup] = useState<boolean>(false);
  const currentCommunityData = useContext(CommunityContext);

  const submitHandler = useCallback(() => {
    doNextStep({ variables: { registrationId } }).then(() => {
      let nextPageURL = `/resident/registration/${registrationId}/documents`;
      if (
        !currentCommunityData?.featuresConfig?.resident?.registration?.documentStepEnabled
      ) {
        nextPageURL = `/resident/registration/${registrationId}/secure-checkout`;
      }
      history.replace(nextPageURL);
    });
  }, [doNextStep, history, registrationId, currentCommunityData]);

  if (showBackup) {
    console.warn('Scorm load error');
  }

  const educationStepNumber = 2;
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationProcessNav
          stepNum={2}
          title={'Step ' + educationStepNumber + ': Community Orientation'}
          subtitle=""
        />
        {/* <GenericAlert
          color="red"
          title="Your community orientation failed to load. Please refresh this page, check
           your connection, and try again later if you continue to have problems"
          hidden={!showBackup}
        /> */}
        <div className={style.videoBox}>
          <div className={style.videoContainer}>
            <ScormWrapper
              continueHandler={submitHandler}
              portal="resident"
              loadFailureHandler={() => setShowBackup(true)}
            />
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton
            title="Continue"
            size="large"
            color="blue"
            clickHandler={submitHandler}
          />
        </div>
      </div>
    </div>
  );
}
