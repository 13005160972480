import { ApolloError, gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import FamilyAndFriendsPassInfo from '../../../common_lib_front/types/familyAndFriendsPassInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

const CREATE_FAMILY_AND_FRIEND_USE_PASS = gql`
  mutation CreateFamilyAndFriendUsePass(
    $startDate: DateTime!
    $endDate: DateTime!
    $passType: String!
  ) {
    registerPass(
      status: "Ready"
      passType: $passType
      registrationId: "resident-family-and-friends-pass"
      startDate: $startDate
      endDate: $endDate
    ) {
      success
      error
      data {
        passId
      }
    }
  }
`;

const CREATE_SHARE_PASS = gql`
  mutation CreateFamilyAndFriendsSharePass(
    $phone: String
    $email: String
    $lastName: String!
    $firstName: String!
    $passId: String!
    $address: String
  ) {
    createSharePass(
      phone: $phone
      email: $email
      lastName: $lastName
      firstName: $firstName
      passId: $passId
      address: $address
    ) {
      success
      error
    }
  }
`;

const ADD_FAMILY_AND_FRIENDS = gql`
  mutation addFamilyMember($data: FamilyMemberInput!) {
    addFamilyMember(data: $data) {
      success
      error
    }
  }
`;

const SAVE_VEHICLE = gql`
  mutation SaveVehicle(
    $passId: String!
    $primaryDriverName: String
    $driverEmail: String
    $driverPhone: String
    $destination: String
  ) {
    registerVehicle(
      passId: $passId
      primaryDriverName: $primaryDriverName
      driverEmail: $driverEmail
      driverPhone: $driverPhone
      destination: $destination
    ) {
      success
      error
    }
  }
`;

type FamilyAndFriendsPass = {
  alert: string;
  alertColor: 'red' | 'green';
  doShare: (data: Array<FamilyAndFriendsPassInfo>) => void;
  successRedirect: boolean;
};

export default function useFamilyAndFriendsPass(): FamilyAndFriendsPass {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [successRedirect, setSuccessRedirect] = useState<boolean>(false);

  const hiddenAlert = () => {
    setTimeout(() => {
      setAlert('');
    }, 4000);
  };

  // helper function to set alert message and color
  const handleAlert = (msg: string, color: 'red' | 'green' = 'red') => {
    setAlert(msg);
    setAlertColor(color);
    hiddenAlert();
  };

  const [addFamily] = useMutation(ADD_FAMILY_AND_FRIENDS, {
    onCompleted: d => {
      // console.log('added', d);
    },
    onError: e => {
      // console.log('error', e.message);
    },
  });

  // helper function to create a family and friend use pass and share it
  const doShare = (data: Array<FamilyAndFriendsPassInfo>): void => {
    // check for completeness
    if (!data.every(u => u.firstName && u.lastName && (u.phoneNumber || u.email))) {
      handleAlert(
        'Please fill out the information for the intended recipient with at least one method of contact',
      );
      return;
    }
    // create family and friend use pass
    data.forEach((user: FamilyAndFriendsPassInfo) => {
      if (user.isNew) {
        addFamily({
          variables: {
            data: {
              phoneNumber: user.phoneNumber || '',
              email: user.email || '',
              firstName: user.firstName,
              lastName: user.lastName,
            },
          },
        });
      }
      backendClient
        .mutate({
          mutation: CREATE_FAMILY_AND_FRIEND_USE_PASS,
          variables: {
            startDate: user.arrivalDate || '',
            endDate: user.departureDate || '',
            passType: user.passType || 'single',
          },
        })
        .then(d => {
          if (d.data.registerPass.success) {
            // share family and friend use pass
            backendClient
              .mutate({
                mutation: CREATE_SHARE_PASS,
                variables: {
                  passId: d.data.registerPass.data[0].passId,
                  phone: user.phoneNumber || null,
                  email: user.email || null,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  address: user.address,
                  // isNew: user.isNew || false,
                },
              })
              .then(d2 => {
                if (d2.data.createSharePass.success) {
                  handleAlert('Pass shared successfully', 'green');
                  setTimeout(() => {
                    setSuccessRedirect(true);
                  }, 2000);
                } else {
                  handleAlert(d2.data.createSharePass.error);
                }
              })
              .catch((e: ApolloError) => handleAlert(e.message));

            backendClient.mutate({
              mutation: SAVE_VEHICLE,
              variables: {
                passId: d.data.registerPass.data[0].passId,
                primaryDriverName: user.firstName + ' ' + user.lastName,
                driverEmail: user.email || null,
                driverPhone: user.phoneNumber || null,
                destination: user.address || null,
              },
            });
          } else {
            handleAlert(d.data.registerPass.error);
          }
        })
        .catch((e: ApolloError) => handleAlert(e.message));
    });
  };

  return {
    alert,
    alertColor,
    successRedirect,
    doShare,
  };
}
