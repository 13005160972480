import {
  gql,
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  useMutation,
  useQuery,
} from '@apollo/client';
import { backendResponse } from '../../types/backendResponse';
import { contactType } from './contactType';

type familyMemberInput = {
  firstName?: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  file?: string;
  fileName?: string;
  driversLicenseNum?: string;
  driversLicenseExp?: string;
  driversLicenseState?: string;
};
type familyMemberResponse = {
  userId: string;
  familyMemberId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  driversLicenseNum: string;
  driversLicenseExp: string;
  isLicenseSelected: boolean;
  memberPictureUrl: string;
  driversLicenseImageUrl: string;
  driversLicenseState: string;
};

export const ADD_FAMILY_FRIEND = gql`
  mutation addFamilyMember($data: FamilyMemberInput!) {
    addFamilyMember(data: $data) {
      success
      error
    }
  }
`;
export type ADD_FAMILY_FRIEND_VARS = {
  data: familyMemberInput;
};
export type ADD_FAMILY_FRIEND_RES = {
  addFamilyMember: backendResponse<undefined>;
};
export const useAddFamilyMember = (
  options: MutationHookOptions<ADD_FAMILY_FRIEND_RES, ADD_FAMILY_FRIEND_VARS>,
) =>
  useMutation<ADD_FAMILY_FRIEND_RES, ADD_FAMILY_FRIEND_VARS>(ADD_FAMILY_FRIEND, {
    ...options,
  });

export const EDIT_FAMILY_FRIEND = gql`
  mutation editFamilyMember($familyMemberId: String!, $data: FamilyMemberInput!) {
    editFamilyMember(familyMemberId: $familyMemberId, data: $data) {
      success
      error
    }
  }
`;
export type EDIT_FAMILY_FRIEND_VARS = {
  familyMemberId: string;
  data: familyMemberInput;
};
export type EDIT_FAMILY_FRIEND_RES = {
  editFamilyMember: backendResponse<undefined>;
};
export const useEditFamilyMember = (
  options: MutationHookOptions<EDIT_FAMILY_FRIEND_RES, EDIT_FAMILY_FRIEND_VARS>,
) =>
  useMutation<EDIT_FAMILY_FRIEND_RES, EDIT_FAMILY_FRIEND_VARS>(EDIT_FAMILY_FRIEND, {
    ...options,
  });

export const GET_FAMILY_FRIEND = gql`
  query Query($familyMemberId: String) {
    getFamilyMember(familyMemberId: $familyMemberId) {
      success
      error
      data {
        familyMemberId
        firstName
        lastName
        phoneNumber
        email
        driversLicenseNum
        driversLicenseExp
        isLicenseSelected
        userId
        memberPictureUrl
        driversLicenseImageUrl
        driversLicenseState
      }
    }
  }
`;
export type GET_FAMILY_FRIEND_VARS = {
  familyMemberId: string;
};
export type GET_FAMILY_FRIEND_RES = {
  getFamilyMember: backendResponse<familyMemberResponse[]>;
};
export const useGetFamilyMember = (
  options: QueryHookOptions<GET_FAMILY_FRIEND_RES, GET_FAMILY_FRIEND_VARS>,
) =>
  useQuery<GET_FAMILY_FRIEND_RES, GET_FAMILY_FRIEND_VARS>(GET_FAMILY_FRIEND, {
    ...options,
  });

export const contactTypeToFamilyMemberInput = (data: contactType): familyMemberInput => ({
  firstName: data.firstName || '',
  lastName: data.lastName || '',
  phoneNumber: data.phoneNumber || '',
  email: data.email || '',
  file: data.fileIsNew ? data.file : undefined,
  fileName: data.fileIsNew ? data.fileName : undefined,
  driversLicenseNum: data.idNum,
  driversLicenseExp: data.idExp,
  driversLicenseState: data.idState,
});
export const familyMemberResponseToContactType = (
  data: familyMemberResponse,
): contactType => ({
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  phoneNumber: data.phoneNumber,
  file: data.driversLicenseImageUrl || data.memberPictureUrl,
  fileName: undefined,
  fileIsNew: false,
  idNum: data.driversLicenseNum,
  idExp: data.driversLicenseExp,
  idState: data.driversLicenseState,
  idType: "Driver's License",
});

type FFAWPassedProps = {
  getQuery: QueryResult<GET_FAMILY_FRIEND_RES, GET_FAMILY_FRIEND_VARS>;
  addMutation: MutationTuple<ADD_FAMILY_FRIEND_RES, ADD_FAMILY_FRIEND_VARS>;
  editMutation: MutationTuple<EDIT_FAMILY_FRIEND_RES, EDIT_FAMILY_FRIEND_VARS>;
};
type FFAWprops = {
  familyMemberId?: string;
  render: (passedProps: FFAWPassedProps) => React.ReactElement;
};

export function FamilyFriendsApiWrapper({
  familyMemberId,
  render,
}: FFAWprops): React.ReactElement {
  const getQuery = useGetFamilyMember({
    variables: {
      familyMemberId: familyMemberId || '',
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !familyMemberId,
  });

  const addMutation = useAddFamilyMember({});
  const editMutation = useEditFamilyMember({});

  return render({ getQuery, addMutation, editMutation });
}
